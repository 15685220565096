import React from 'react';
import {mincodeServicesGeneratePDF, translateTicketActionList} from '../../functional/logic';
import {kAPIEndpointPVI} from '../../functional/api';
import {colorByStatus, kStatusRepaired} from '../../functional/status';
import TextFieldDisplay from '../input/TextFieldDisplay';
import {prettyDateAndTime} from '../../functional/min-support';
import {toClientPage} from '../../functional/navigation';
import HorizontalSnapper from '../lists/HorizontalSnapper';
import ImageFieldDisplay from '../input/ImageFieldDisplay';
import TimelineList from '../lists/TimelineList';
import {ticketActionAdvancedFields} from '../../pages/TicketPage';
import PropTypes from 'prop-types';
import {openPDFViewModal, openUpdateModal, setItemPageTitle} from '../../store/actions/application-store-actions';
import {connect} from 'react-redux';

const prioNbBasedOnText = (prioText) => {
    if (prioText === 'normal')  {
        return 'prio 2';
    } else if (prioText === 'low') {
        return 'prio 3';
    } else if (prioText === 'high') {
        return 'prio 1'
    } else if (prioText === 'urgent') {
        return 'urgent';
    }
    return prioText;
}

class ListItem extends React.Component {
    
    onPVIPressed = (id) => {
        //openPVIServices(id);
        
        this.props.openPDFViewModal('loading');
        
        mincodeServicesGeneratePDF(kAPIEndpointPVI + '?id=' + id, (successful, result) => {
            if(successful) {
                this.props.openPDFViewModal(
                    result.versions[0].extended,
                    {
                        type: 'pvi',
                        clientId: this.props.item.client.id,
                        subject: 'Proces verbal de interventie nr. ' + (parseInt(20000 + parseInt(id))) + ' pentru ' + this.props.item.name,
                    });
            }
        });
    }
    
    render() {
        let props = this.props;
        let overhaul = false;
        let addStyle = {alignItems: 'stretch'};
        if(props.item['deviz_created'] && props.item.status == kStatusRepaired) {
            overhaul = true;
            addStyle.backgroundColor = '#bbeebb';
        }
        let iconColor = '#333';
        if(props.item.priority == 'low') {
            iconColor = '#2d530c';
        } else if (props.item.priority == 'normal') {
            iconColor = '#936a0f';
        } else if (props.item.priority == 'high' || props.item.priority == 'urgent') {
            iconColor = '#6c1b1b';
        }
        
        let actions = translateTicketActionList(props.item.actions);
        
        return(
            <div className={'sectioned-list-item'} style={{borderColor: colorByStatus(props.item.status)}}>
                
                <div className="sectioned-list-item-card" style={addStyle}>
                    
                    <div className={'list-item-section'}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <TextFieldDisplay
                                oneLine
                                value={props.item.name}
                                style={{flex: 1}}/>
                            
                            
                            {
                                props.item.priority &&
                                <div className={'prio-status'} style={{backgroundColor: iconColor}}>
                                    {prioNbBasedOnText(props.item.priority)}
                                </div>
                            }
                        
                        </div>
                        
                        <TextFieldDisplay
                            oneLine
                            value={prettyDateAndTime(props.item.created)}
                            icon={'ft-calendar'}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            value={props.item.type}
                            icon={'ft-clipboard'}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            value={props.item.status}
                            icon={'ft-code'}
                            textBackgroundColor={colorByStatus(props.item.status)}
                            textColor={'#ffffff'}
                            style={{marginBottom: 4}}/>
                    </div>
                    
                    <div className={'list-item-section'} style={{borderLeftWidth: 1}}>
                        <TextFieldDisplay
                            oneLine
                            value={props.item.client.headquarters.address}
                            icon={'ft-map-pin'}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            label={'client'}
                            value={props.item.client.name}
                            icon={'ft-briefcase'}
                            action={'ft-eye'}
                            onPress={() => {toClientPage(props.item.client.id)}}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            label={'beneficiar'}
                            value={props.item.beneficiary}
                            icon={'ft-copy'}
                            style={{marginBottom: 4}}/>
                    </div>
                    
                    <div className={'list-item-section-with-button'}>
                        
                        <div className={'inside-list-item-section'}>
                            {
                                props.item.equipments.map((equipment, index) => {
                                    return(
                                        <TextFieldDisplay
                                            oneLine
                                            key={equipment.id}
                                            value={equipment.title}
                                            icon={'ft-chevron-right'}
                                            style={{marginBottom: 4}}/>
                                    );
                                })
                            }
                            {
                                overhaul &&
                                <TextFieldDisplay
                                    oneLine
                                    label={'deviz creat'}
                                    value={'tichet reparat si deviz creat'}
                                    icon={'ft-chevrons-right'}
                                    style={{marginBottom: 4}}/>
                            }
                            
                            {
                                props.item.images && props.item.images.length > 0 &&
                                <HorizontalSnapper small items={props.item.images} renderItem={(item) => {
                                    return (
                                        <div style={{height: 140, width: 240}}>
                                            <ImageFieldDisplay value={item['services_picture_uid_m']} full={item['services_picture_uid_o']}/>
                                        </div>
                                    );
                                }}/>
                            }
                        </div>
                        
                        <div className={'item-action-button'}>
                            <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                                {'Vezi tichetul'}
                            </div>
                        </div>
                    
                    </div>
                
                </div>
                
                <TimelineList
                    horizontal
                    items={actions}
                    renderItem={(item) => {
                        ////, border: 'dashed 2px #333', borderColor: colorByStatus(item.status), boxShadow: 'none'
                        return (
                            <div className="general-card card-small-padding h-100" style={{paddingLeft: 20}} >
                                <TextFieldDisplay
                                    textBackgroundColor={colorByStatus(item.status)}
                                    textColor={'#ffffff'}
                                    style={{marginBottom: 4}}
                                    value={item.status}
                                    icon={'ft-code'}/>
                                
                                <TextFieldDisplay
                                    style={{marginBottom: 4}}
                                    value={prettyDateAndTime(item.created)}
                                    icon={'ft-clock'}/>
                                
                                <TextFieldDisplay
                                    style={{marginBottom: 4}}
                                    value={item.user['first_name'] + ' ' + item.user['last_name']}
                                    icon={'ft-users'}/>
                                
                                {
                                    ticketActionAdvancedFields(item, this.onPVIPressed)
                                }
                            </div>
                        );
                    }}/>
            
            </div>
        );
    }
    
};

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link, data) => dispatch(openPDFViewModal(link, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);