import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getBriefListHeadquarters} from '../../../functional/api';
import PickerModal from '../model/PickerModal';
import AddHeadquartersModal from '../AddHeadquartersModal';

class Picker extends Component {

    static propTypes = {
        parentKey: PropTypes.any,

        open: PropTypes.bool,
        value: PropTypes.any,

        onClose: PropTypes.func,
        onDone: PropTypes.func,

        setLabel: PropTypes.func,
    };

    state = {
        options: [],

        modalOpen: false,
    };

    componentDidMount() {
        this.update(this.props.parentKey);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.parentKey != nextProps.parentKey) {
            this.update(nextProps.parentKey);
        }
        if(this.props.value != nextProps.value) {
            this.updateLabel(nextProps.value);
        }
    }

    update = (parentKey = null) => {
        getBriefListHeadquarters(parentKey, (successful, data) => {
            if(successful) {
                this.setState({
                    options: data,
                }, () => {
                    this.updateLabel(this.props.value);
                });
            } else {
                console.error('data retrieve error');
            }
        });
    };

    render() {
        return (
            <PickerModal
                open={this.props.open}
                options={this.state.options}
                initialSelectedValues={[this.props.value]}

                title={'Alege punctul de lucru'}

                search={false}
                searchInDescription={false}

                minimumChoices={1}
                maximumChoices={1}

                onClose={this.props.onClose}
                onDone={this.props.onDone}

                onMore={() => {
                    this.setState({
                        modalOpen: true,
                    });
                }}
                onMoreText={'Adauga punct de lucru'}
                onMoreIcon={'ft-map-pin'}>

                <AddHeadquartersModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update(this.props.parentKey);
                    }}
                    parentKey={this.props.parentKey}/>

            </PickerModal>
        );
    }

    updateLabel = (newValue) => {
        if(this.props.setLabel) {
            for (let i = 0; i < this.state.options.length; ++i) {
                if (this.state.options[i].value === newValue) {
                    this.props.setLabel(this.state.options[i].label);
                    return;
                }
            }
            this.props.setLabel(-1);
        }
    };
}

export default Picker;