import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import InternalToolbar from '../components/general/InternalToolbar';
import {Card, CardBody, Col, Row, Table} from 'reactstrap';
import TicketCountChartOld from '../components/dashboard/charts/TicketCountChartOld';
import femaleImage from '../assets/images/global/user-placeholder-female.png';
import maleImage from '../assets/images/global/user-placeholder-male.png';
import BarChart from '../components/reports/barchart';
import LineChart from '../components/reports/linechart';
import DountChart from '../components/reports/dountchart';
import PieChart from '../components/reports/piechart';
import PolarChart from '../components/reports/polarchart';
import RadarChart from '../components/reports/radarchart';

class ReportsPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        search: '',
    };
    
    state = ReportsPage.defaultState;
    
    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
    };
    
    componentDidMount() {
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(ReportsPage.defaultState, this.update);
    }
    
    update = () => {
    
    };
    
    render () {
        return (
            <React.Fragment>
        
                <InternalToolbar
                    refresh
                    onRefesh={this.generalUpdate}
                    search
                    onSearchChange={this.searchChange}
                    searchValue={this.state.search}/>
                    
                <div className={'scrollable-page ps-4 pb-4'}>
    
                    <Row className="w-100">
                        <Col className="col-8 stretched_card pt-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card_title">Statusul tichetelor</h4>
                                    <div className="d-flex mt-2 total_sales_sec mt-3">
                                        <div className="col-md-5">
                                            <p className="h1 lh-10 m-0 fw-300"><span>21 total - 6 azi</span>
                                            </p><small className="small text-success fs-11">Toate tichetele</small>
                                        </div>
                                        <div className="col-md-7 row no-gutters mt-2">
                                            <div className="col-sm-6">
                                                <div className="sales-info"><span className="sales-info-box bg-success mr-2"></span>
                                                    <div className="sales-info-body">
                                                        <h6>Numar clienti</h6><small><span className="text-success"><i className="feather ft-chevron-up"></i> 6</span> de saptamana trecuta</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="sales-info"><span className="sales-info-box bg-primary mr-2"></span>
                                                    <div className="sales-info-body">
                                                        <h6>Numar facturi</h6><small><span className="text-danger"><i className="feather ft-chevron-down"></i> 1</span> de saptamana trecuta</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart_container mt-3">
                                        <TicketCountChartOld />
                                    </div>
                                </div>
                            </div>
                        </Col>
        
                        <Col lg="4" className="stretched_card pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title mb-3">Activitati recente</h4>
                                    <div className="table-responsive">
                                        <Table className="table table-centered table-hover mb-0">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className="media recent_activity mt-2 d-flex">
                                                        <img className="mr-3 rounded-circle" src={femaleImage} style={{width: 50, height: 50, marginRight: 10}} width="50" height="50" alt="ImageText" />
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">Contabilitate
                                                                <small className="font-weight-normal d-block mt-1">
                                                                    10 Iunie 14:09
                                                                </small>
                                                            </h6>
                                                            <span className="mt-2 d-block">A creata factura PDP20010</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                            
                                            <tr>
                                                <td>
                                                    <div className="media recent_activity mt-2 d-flex">
                                                        <img className="mr-3 rounded-circle" src={maleImage} style={{width: 50, height: 50, marginRight: 10}} width="50" height="50" alt="TextALt" />
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">Mugur - Nicolae Tomita
                                                                <small className="font-weight-normal d-block mt-1">
                                                                    10 Iunie 11:33
                                                                </small>
                                                            </h6>
                                                            <span className="mt-2 d-block">A trecut tichetul #20032 in statusul In lucru.</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
    
                    <Row className="w-100">
                        <Col lg="6" className="pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 1</h4>
                                    <div className="chart_container">
                                        <BarChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
        
                        <Col lg="6" className="pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 2</h4>
                                    <div className="chart_container">
                                        <LineChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
    
                    <Row className="w-100">
                        <Col lg="6" className="stretched_card pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 3</h4>
                                    <div className="chart_container">
                                        <DountChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
        
                        <Col lg="6" className="stretched_card pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 4</h4>
                                    <div className="chart_container">
                                        <PieChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
    
                    <Row className="w-100">
                        <Col lg="6" className="stretched_card pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 5</h4>
                                    <div className="chart_container">
                                        <PolarChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
        
                        <Col lg="6" className="stretched_card pt-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card_title">Raport 6</h4>
                                    <div className="chart_container">
                                        <RadarChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
    
            </React.Fragment>
        );
    }
}

export default withRouter(ReportsPage);