import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllSuppliers} from '../functional/api';
import {withRouter} from 'react-router-dom';
import ListPage from '../layout/page/ListPage';
import {kFilterSupplierFields, kFilterSupplierFieldsDefault} from '../functional/constants';
import AddSupplierModal from '../components/modal/AddSupplierModal';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {toSupplierPage} from '../functional/navigation';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class SuppliersPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = SuppliersPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-suppliers-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(SuppliersPage.defaultState, this.update);
    }
    
    update = () => {
        getAllSuppliers((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no suppliers');
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items == null}
                actions={[{
                    title: 'Adauga furnizor',
                    icon: {title: 'ft-plus'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterSupplierFieldsDefault}
                allFields={kFilterSupplierFields}>

                <AddSupplierModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toSupplierPage(item.id);
    }
    
}

const ListItem = (props) => {
    return(
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>

            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
            
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.cif}
                        icon={'ft-info'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.reg}
                        icon={'blank'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.bank}
                        icon={'ft-credit-card'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.iban}
                        icon={'blank'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.address}
                        icon={'ft-map-pin'}
                        style={{marginBottom: 4}}/>
                </div>
    
    
                <div className={'item-action-button'}>
                    <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                        {'Vezi furnizorul'}
                    </div>
                </div>
                
            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(SuppliersPage);