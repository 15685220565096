import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import App from './App';

import applicationStoreReducer from './store/reducers/application-store-reducer';

const applicationStore = createStore(applicationStoreReducer);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={applicationStore}>
            <BrowserRouter basename={'/'}>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
