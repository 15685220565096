import React from 'react';
import PropTypes from 'prop-types';
import {getAllNecessaryItems} from '../functional/api';
import {withRouter} from 'react-router-dom';
import ListPage from '../layout/page/ListPage';
import {kFilterNecessaryFields, kFilterNecessaryFieldsDefault} from '../functional/constants';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {toNecessaryItemPage} from '../functional/navigation';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class NecessaryPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
    };
    
    state = NecessaryPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-necessary-page';
    }

    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(NecessaryPage.defaultState, this.update);
    }
    
    update = () => {
        getAllNecessaryItems((successful, data) => {
            console.log('necessary page', data);
            if(successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no necessary');
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items == null}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterNecessaryFieldsDefault}
                allFields={kFilterNecessaryFields}>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toNecessaryItemPage(item.id);
    }
    
}

const ListItem = (props) => {
    
    return(
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>
            
            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
                
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}/>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.nb + ' buc.'}
                        icon={'ft-package'}/>
                </div>
                
                <div className={'list-item-section-with-button'} style={{flex: 1}}>
        
                    <div className={'item-action-button'}>
                        <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                            {'Vezi piesa'}
                        </div>
                    </div>
    
                </div>
                
            </div>
        
        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(NecessaryPage);