import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from '../../components/general/LoadingAnimation';
import InternalToolbar from '../../components/general/InternalToolbar';
import {filter, range} from '../../functional/min-support';
import PickerModal from '../../components/modal/model/PickerModal';
import PersistentStateComponent from "../../components/extends/PersistentStateComponent";

class ListPage extends PersistentStateComponent {

    static baseIncrement = 20;
    static loadOffset = 100;

    currentScroll = 0;

    static propTypes = {
        onRefresh: PropTypes.func,
        loading: PropTypes.bool,
        deepSearch: PropTypes.bool,

        grid: PropTypes.bool,

        actionText: PropTypes.string,
        onAction: PropTypes.func,
        
        allListItems: PropTypes.array,
        renderListItem: PropTypes.func,
        renderEmpty: PropTypes.func,

        allFields: PropTypes.array,
        defaultFields: PropTypes.array,

        children: PropTypes.array,
        persistentIdentifier: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.listElement = React.createRef();
        this.persistentExcludedState = [];
        this.persistentIdentifier = this.props.persistentIdentifier;
    }

    componentDidMount() {
        super.componentDidMount();
        let savedScroll = window.localStorage.getItem(this.persistentIdentifier + '-scroll');
        if(savedScroll) {
            this.currentScroll = savedScroll;
        }
        setTimeout(() => {
            if(this.state.fields.length === 0 && this.props.defaultFields) {
                this.setState({
                    fields: this.props.defaultFields,
                });
            }
        }, 500);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(!nextProps.loading && this.props.loading) {
            setTimeout(() => {
                if(this.currentScroll && this.listElement && this.listElement.current){
                    this.listElement.current.scrollTo(0, this.currentScroll);
                }
            }, 0);
        }
    }

    componentWillUnmount() {
        window.localStorage.setItem(this.persistentIdentifier + '-scroll', this.currentScroll + '');
        super.componentWillUnmount();
    }

    state = {
        search: '',
        fields: [],
        filterModal: false,

        maxElement: ListPage.baseIncrement,
        
        toolbarShadow: false,
    };

    lastSize = 0;

    matchFields = (fields) => {
        let matchedFields = [];
        for(let i = 0; i < this.props.allFields.length; ++i) {
            if(fields.includes(this.props.allFields[i].value)) {
                matchedFields.push(this.props.allFields[i]);
            }
        }
        return matchedFields.map(item => item.label);
    };

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
            maxElement: ListPage.baseIncrement,
        });
        this.lastSize = 0;
    };

    handleScroll = (e) => {
        /*if(e.target.scrollTop > 100 && !this.state.toolbarShadow) {
            this.setState({toolbarShadow: true});
        } else if(e.target.scrollTop <= 100 && this.state.toolbarShadow){
            this.setState({toolbarShadow: false});
        }*/
        let bottom = e.target.scrollHeight - e.target.scrollTop < ListPage.loadOffset + e.target.clientHeight;
        this.currentScroll = e.target.scrollTop;
        if(bottom && this.state.maxElement > this.lastSize) {
            this.lastSize = this.state.maxElement;
            this.addToList();
        }
    };

    addToList = () => {
        this.setState({
            maxElement: this.state.maxElement + ListPage.baseIncrement,
        });
    };

    render () {
        let overallStyle = {};
        
        let style = {};
        if(this.props.grid) {
            style.display = 'flex';
            style.flexDirection = 'row';
            style.flexWrap = 'wrap';
            style.justifyContent = 'space-around';
        }
        
        if(this.props.paddingOverall) {
            style.padding = 20;
        }
        
        let list = [];
        if(!this.props.loading) {
            let elements = filter(this.props.allListItems, this.state.fields, this.state.search, this.props.deepSearch).slice(0, this.state.maxElement);
            if(this.props.grid) {
                list = elements.map((element, index) => {
                    let dark = (index % 2 === 1 && index % 8 < 4) || (index % 2 === 0 && index % 8 >= 4);
                    return this.props.renderListItem(element, dark);
                });
            } else {
                list = elements.map(this.props.renderListItem);
            }
        }
        
        let emptyElementsNb = 0;
        if(this.props.grid) {
            emptyElementsNb = (4 - (list.length % 4)) % 4;
            style.paddingRight = 20;
            style.paddingTop = 20;
        }
        
        let toolbarActions = [{
            title: 'Filtre',
            onPress: this.openFilterModal,
            icon: {title: 'ft-filter'}
        }];
        if(this.props.actions) {
            toolbarActions.push(...this.props.actions);
        }
        
        let toolbarLabels = [{
            connection: 'se cauta dupa:',
            tags: this.matchFields(this.state.fields)
        }];
        if(this.props.labels) {
            toolbarLabels.push(...this.props.labels);
        }

        return (
            <React.Fragment>

                <div className={'list-page'} style={overallStyle}>

                    <InternalToolbar
                        refresh
                        onRefresh={this.props.onRefresh}
                        search
                        onSearchChange={this.searchChange}
                        searchValue={this.state.search}
                        actions={toolbarActions}
                        labels={toolbarLabels}/>
                        
                    {
                        false && this.props.renderHeaderElement && this.props.headerElements && this.props.headerElements.length > 0 &&
                            <div className={'header-elements'}>
                                {
                                    this.props.headerElements.map((item) => {
                                        return this.props.renderHeaderElement(item);
                                    })
                                }
                            </div>
                    }
                    
                    {
                        this.props.loading &&
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    }
                    
                    {
                        !this.props.loading &&
                        <div className={'list-container'} style={style} onScroll={this.handleScroll} ref={this.listElement}>
    
                            {
                                list
                            }
    
                            {
                                this.props.grid && range(0, emptyElementsNb).map(this.renderEmpty)
                            }

                        </div>
                    }

                </div>

                <PickerModal
                    title={'Alege campurile cautarii'}
                    open={this.state.filterModal}
                    options={this.props.allFields}
                    initialSelectedValues={this.state.fields}
                    minimumChoices={1}
                    maximumChoices={100}
                    onClose={this.closeFilterModal}
                    onDone={this.doneFilterModal}
                    returnMatchedFields/>

                {
                    this.props.children
                }

            </React.Fragment>
        );
    }
    
    renderEmpty = () => {
        return (<div className={'grid-list-item-empty'}/>);
    }

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };
}

export default ListPage;