import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import {getClient, getSale, getSupplier} from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {filter, prettyDateAndTime} from '../functional/min-support';
import {toSupplierPage, toStockItemPage, toTicketPage, toOrderPage} from '../functional/navigation';
import {
    openSale,
    translateClientHeadquarters,
    translateClientstock,
    translateSaleheadquarters, translateSupplierOrders, translateSupplierStock
} from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';

class SupplierPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };
    
    state = SupplierPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(SupplierPage.defaultState, this.update);
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getSupplier(key, (successful, data) => {
            if(successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let stock = this.state.item ? translateSupplierStock(this.state.item.stock) : null;
        let orders = this.state.item ? translateSupplierOrders(this.state.item.orders) : null;
        let toolbarActions = [
            {
                title: 'Creaza comanda',
                onPress: () => {this.setState({modalOpen: true});},
                icon: {title: 'ft-plus'}
            },
        ];
        let toolbarLabels = [
            {
                connection: 'se cauta produsele dupa:',
                tags: ['Denumirea produsului']
            },
            {
                connection: 'se cauta comenzile dupa:',
                tags: ['Denumirea comenzii', 'Statusul comenzii']
            },
        ];
        if(stock && this.state.search.length > 0) {
            stock = filter(stock, ['name'], this.state.search, false);
        }
    
        if(orders && this.state.search.length > 0) {
            orders = filter(orders, ['name', 'status'], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                    
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{flex: 1}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'} style={{margin: 20}}>
    
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{marginBottom: 4}}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'supplier',
                                                'name');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'cod identificare fiscala'}
                                        value={this.state.item.cif}
                                        icon={'ft-info'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.cif,
                                                'supplier',
                                                'cif');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'nr. inregistrare la registrul comertului'}
                                        value={this.state.item.reg}
                                        icon={'blank'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.reg,
                                                'supplier',
                                                'reg');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'banca'}
                                        value={this.state.item.bank}
                                        icon={'ft-credit-card'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.bank,
                                                'supplier',
                                                'bank');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'IBAN cont bancar'}
                                        value={this.state.item.iban}
                                        icon={'blank'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.iban,
                                                'supplier',
                                                'iban');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'adresa sediului social'}
                                        value={this.state.item.address}
                                        icon={'ft-map-pin'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.address,
                                                'supplier',
                                                'address');
                                        }}/>
                            
                                </div>
    
                                {
                                    stock && stock.length > 0 &&
                                    <div className="w-100 d-flex flex-row flex-wrap" style={{paddingLeft: 20, paddingBottom: 20}}>
                                        {
                                            stock.map((item) => {
                                                return (
                                                    <div style={{width: '33.3333%', paddingRight: 20, paddingBottom: 20}}>
                                                        <div className="general-card" style={{padding: 0}}>
                                                            <div style={{width: '100%', height: 300}}>
                                                                <ImageFieldDisplay value={item['services_picture_uid_m']} full={item['services_picture_uid_o']} imageStyle={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}/>
                                                            </div>
                                                            <div style={{padding: 20}}>
                                                                <TextFieldDisplay
                                                                    icon={'ft-package'}
                                                                    oneLine
                                                                    value={item.name}
                                                                    style={{marginBottom: 4}}/>
    
                                                                <TextFieldDisplay
                                                                    oneLine
                                                                    value={(item.own ? item.own : 0) + ' ' + item.counter}
                                                                    icon={'ft-layers'}
                                                                    style={{marginBottom: 4}}/>
    
                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    value={'Vezi produsul'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => {toStockItemPage(item.id)}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
    
                                {
                                    stock && stock.length <= 0 &&
                                    <h3 style={{marginTop: 20, paddingLeft: 20}}>
                                        Niciun produs
                                    </h3>
                                }

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
    
                    <div className="h-100 overflow-scroll" style={{width: '25%', padding: 20, paddingLeft: 0}}>
        
                        {
                            this.state.item && orders &&
                            orders.map((item) => {
                                return (
                                    <div className="general-card card-small-padding" style={{marginBottom: 20}}>
                                        <TextFieldDisplay
                                            style={{marginBottom: 4}}
                                            value={item.name}
                                            icon={'ft-shopping-bag'}/>
    
                                        <TextFieldDisplay
                                            value={item.status}
                                            icon={'ft-code'}
                                            style={{marginBottom: 4}}/>
    
                                        <TextFieldDisplay
                                            value={prettyDateAndTime(item.created)}
                                            icon={'ft-clock'}
                                            style={{marginBottom: 4}}/>
    
                                        <TextFieldDisplay
                                            icon={'blank'}
                                            action={'ft-eye'}
                                            onPress={()=>{toOrderPage(item['id'])}}
                                            value={'Vezi comanda'}/>
                                    </div>
                                );
                            })
                        }
        
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
    
                        {
                            orders && orders.length <= 0 &&
                            <h3>
                                Nicio comanda
                            </h3>
                        }
    
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierPage));