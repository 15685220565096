let globalHistory = null;

export const bindHistory = (p_history) => {
    globalHistory = p_history;
}

export const toSalePage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/sales/' + uid);
    }
}

export const toTicketPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/tickets/' + uid);
    }
}

export const toStockItemPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/stock/' + uid);
    }
}

export const toClientPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/clients/' + uid);
    }
}

export const toInvoicePage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/invoices/' + uid);
    }
}

export const toSupplierPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/suppliers/' + uid);
    }
}

export const toNecessaryItemPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/necessary/' + uid);
    }
}

export const toOrderPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/orders/' + uid);
    }
}

export const toUserPage = (uid) => {
    if(globalHistory) {
        globalHistory.push('/users/' + uid);
    }
}