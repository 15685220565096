import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';


import { Document, Page, pdfjs } from "react-pdf";


//import { Page, Document } from 'react-pdf';
//import { Document } from 'react-pdf/dist/esm/entry.webpack';

// Core viewer
import { Viewer } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {kToastActionError, kToastActionNormal, kToastActionWarn} from '../general/Toast';
import AddMailModal from './AddMailModal';

// Create new plugin instance
//const defaultLayoutPluginInstance = defaultLayoutPlugin();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class UpdateModal extends Component {
    
    static propTypes = {
        pdfViewModal: PropTypes.bool,
        pdfViewModalInfo: PropTypes.object,
        
        history: PropTypes.object,
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,
        
        toast: PropTypes.func,
        
        sendMailDefaultData: null,
    };
    
    state = {
        value: '',
        
        sendMailModalOpen: false,
        sendMailDefaultData: null,
    };
    
    componentWillReceiveProps(nextProps, nextContext) {
        if((!this.props.pdfViewModal && nextProps.pdfViewModal) || (this.props.pdfViewModalInfo !== nextProps.pdfViewModalInfo)) {
            this.update(nextProps);
        }
    }
    
    update = (props) => {
        let sendMailDefaultData = null;
        if(props && props.pdfViewModal && props.pdfViewModalInfo && props.pdfViewModalInfo.data) {
            if(props.pdfViewModalInfo.data.type === 'pvi') {
                sendMailDefaultData = {
                    clientId: props.pdfViewModalInfo.data.clientId,
                    subject: props.pdfViewModalInfo.data.subject,
                    body: 'Acest email a fost trimis automat de catre aplicatia Polidom. La urmatorul link puteti gasi fisierul cu procesul verbal:\n\n' + props.pdfViewModalInfo.url,
                    signature: 'echipa-polidom',
                }
            }
        }
        this.setState({
            sendMailDefaultData: sendMailDefaultData,
        });
    };
    
    onDocumentLoadSuccess = () => {
    
    }
    
    onSendEmail = () => {
        this.setState({
            sendMailModalOpen: true,
        });
        /*this.props.openLoading();
        setTimeout(() => {
            this.props.closeLoading();
            this.props.toast("Fisierul a fost trimis cu succes.");
        }, 2000);*/
    }
    
    render () {
        return (
            <BaseModal
                title={'Vezi PDF'}
                stableHeight
                onMore={this.isLoading ? null : this.onSendEmail}
                onMoreText={'Trimite pe mail'}
                open={this.props.open}
                doneActive={!this.isLoading && !this.hasFailed}
                onClose={this.onClose}
                onDone={this.onDone}>
    
                {
                    this.isLoading &&
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                }
                
                {
                    !this.isLoading && !this.hasFailed &&
                    <Document
                        file={{ url: this.props.pdfViewModalInfo.url }}
                        options={{ workerSrc: "/pdf.worker.js" }}
                        onLoadError={(error)=>{
                            console.log('document load error', error);
                            window.open(this.props.pdfViewModalInfo.url);
                        }}
                        onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page pageNumber={1} />
                    </Document>
                }
                
                <AddMailModal
                    open={this.state.sendMailModalOpen}
                    defaultData={this.state.sendMailDefaultData}
                    onClose={() => {
                        this.setState({
                            sendMailModalOpen: false,
                        });
                        this.update();
                    }}/>
                
            </BaseModal>
        );
    }
    
    get isLoading () {
        return this.props.pdfViewModalInfo.url === 'loading';
    }
    
    get hasFailed () {
        return this.props.pdfViewModalInfo.url === 'failed' || this.props.pdfViewModalInfo.url === '';
    }
    
    onDocumentLoadSuccess({ numPages }) {
    
    }
    
    onDone = () => {
        window.open(this.props.pdfViewModalInfo.url);
    };
    
    onClose = () => {
        this.props.onClose();
    }
    
    onMore = () => {
    
    };
}

const mapStateToProps = (state) => {
    return {
        pdfViewModal: state.modals.pdfViewModal,
        pdfViewModalInfo: state.pdfViewModalInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
        toast: (label, action) => dispatch(snackAdd(label, action)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateModal));