import React from 'react';

class PlaceholderPage extends React.Component {
    render() {
        return (
            <div>
                Placeholder....
            </div>
        )
    }
}

export default PlaceholderPage;