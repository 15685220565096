import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class LineChart extends Component {

    render() {
        const data = {
            labels: ['Lun', 'Ma', 'Mie', 'Joi', 'Vin', 'Sam', 'Dum'],
            datasets: [{
                label: 'Principal',
                data: [12, 19, 3, 17, 6, 3, 7],
                backgroundColor: "#312f2f",
                borderColor: "#443c3c",
                borderWidth: 2
            }, {
                label: 'De baza',
                data: [2, 29, 5, 5, 2, 3, 10],
                backgroundColor: "#6c1b1b",
                borderColor: "#d63735",
                borderWidth: 2
            }]
        };

        const option = {
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },

            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }]
            }
        }

        return (
            <React.Fragment>
                <Line width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default LineChart;   