import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllStockModern} from '../functional/api';
import {withRouter} from 'react-router-dom';
import {
    kFilterStockFields,
    kFilterStockFieldsDefault,
    kTimeoutDurationForPersistentStateRetrieval
} from '../functional/constants';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import CloseIcon from '@material-ui/icons/Close';
import ListPage from '../layout/page/ListPage';
import AddOrderModal from '../components/modal/AddOrderModal';
import PickerModal from '../components/modal/model/PickerModal';
import SupplierMultiplePicker from '../components/modal/pickers/SupplierMultiplePicker';
import OperatorMultiplePicker from '../components/modal/pickers/OperatorMultiplePicker';
import {smallIcon} from '../functional/min-support';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import AddStockModal from '../components/modal/AddStockModal';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class StockPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    
        suppliers: [],
        supplierLabels: [],
        operators: [],
        operatorLabels: [],
        search: '',
        sortType: 'Data adaugarii',
    
        loading: false,
    };
    
    state = StockPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-stock-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        setTimeout(() => {
            this.update();
        }, kTimeoutDurationForPersistentStateRetrieval);
    }
    
    retrieveInitial = (operators) => {
        getAllStockModern(this.state.suppliers, operators, this.state.search, 0, 21, this.state.sortType, (successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                    loading: false,
                });
            } else {
                console.error('no entries');
                this.setState({
                    loading: true,
                });
            }
        });
    }

    filterBySupplier = (original, suppliers) => {
        if(original && suppliers && suppliers.length > 0 && suppliers[0] != -1) {
            let result = [];
            for (let i = 0; i < original.length; ++i) {
                for (let j = 0; j < suppliers.length; ++j) {
                    if(original[i]['supplier_id'] == suppliers[j]) {
                        result.push(original[i]);
                        break;
                    }
                }
            }
            return result;
        }
        return original;
    }
    
    generalUpdate = () => {
        this.setState(StockPage.defaultState, this.update);
    }
    
    update = (operators = null) => {
        this.setState({
            items: null,
            loading: true,
        });
        if(!operators) {
            operators = this.state.operators;
        }
        this.retrieveInitial(operators);
    };
    
    render () {
        let list = this.filterBySupplier(this.state.items, this.state.suppliers);
        let toolbarActions = [
            {
                title: 'Operatorul',
                icon: {title: 'ft-users'},
                onPress: this.openOperatorModal
            },
            {
                title: 'Furnizorul',
                icon: {title: 'ft-package'},
                onPress: this.openSupplierModal
            },
            {
                title: 'Ordonare',
                icon: {title: 'ft-chevrons-down'},
                onPress: this.openSortModal
            },
            {
                title: 'Adauga piesa',
                icon: {title: 'ft-plus'},
                onPress: () => {
                    this.setState({
                        modalOpen: true,
                    });
                }
            }
        ];
        let toolbarLabels = [
            {
                connection: 'pentru operatorul: ',
                tags: this.getSecondLabels(this.state.operatorLabels),
            },
            {
                connection: 'pentru furnizorul: ',
                tags: this.getSecondLabels(this.state.supplierLabels),
            },
            {
                connection: 'ordonare dupa: ',
                tags: [this.getSecondLabels(this.state.sortType)],
            },
        ];
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items == null}
                actions={toolbarActions}
                labels={toolbarLabels}
                allListItems={list}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterStockFieldsDefault}
                allFields={kFilterStockFields}
                
                additionalActions={['Operatorul', 'Furnizorul', 'Ordonare']}
                onAdditionalActions={[this.openOperatorModal, this.openSupplierModal, this.openSortModal]}
                additionalLabels={[this.getSecondLabels(this.state.operatorLabels), this.getSecondLabels(this.state.supplierLabels), this.getSecondLabels([this.state.sortType])]}
                connectionText={['pentru operatorul:', 'pentru furnizorul:', 'ordonare dupa:']}>
            
                <AddStockModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>
    
                <PickerModal
                    title={'Alege modul de ordonare'}
                    open={this.state.sortModal}
                    options={[{label: 'Data adaugarii', value: 'Data adaugarii'}, {label: 'Numarul de bucati', value: 'Numarul de bucati'}]}
                    initialSelectedValues={[this.state.sortType]}
                    minimumChoices={1}
                    maximumChoices={1}
                    onClose={this.closeSortModal}
                    onDone={(newValues) => {this.setState({sortModal: false, sortType: newValues[0]}, () => {this.update();});}}/>
    
                <SupplierMultiplePicker
                    minimumChoices={0}
                    maximumChoices={1}
                    open={this.state.supplierModal}
                    values={this.state.suppliers}
                    onClose={this.closeSupplierModal}
                    onDone={(newValues) => {this.setState({supplierModal: false, suppliers: newValues});}}
                    setLabels={(labels) => {
                        this.setState({
                            supplierLabels: labels,
                        });
                    }}/>
    
                <OperatorMultiplePicker
                    minimumChoices={0}
                    maximumChoices={1}
                    open={this.state.operatorModalOpen}
                    values={this.state.operators}
                    onClose={this.closeOperatorModal}
                    onDone={(newValues) => {this.setState({operatorModalOpen: false, operators: newValues}); this.update(newValues);}}
                    setLabels={(labels) => {
                        this.setState({
                            operatorLabels: labels,
                        });
                    }}/>
        
            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        if(this.state.operatorLabels && this.state.operatorLabels.length > 0) {
            this.props.history.push('/stock/' + item.id, {search: this.state.operatorLabels[0]});
        } else {
            this.props.history.push('/stock/' + item.id);
        }
    }
    
    getSecondLabels = (list) => {
        if(list && list.length > 0) {
            return list;
        } else {
            return ['Toti'];
        }
    }
    
    openSupplierModal = () => {
        this.setState({
            supplierModal: true,
        });
    }
    
    openSortModal = () => {
        this.setState({
            sortModal: true,
        });
    }
    
    openOperatorModal = () => {
        this.setState({
            operatorModalOpen: true,
        });
    }
    
    
    closeSupplierModal = () => {
        this.setState({
            supplierModal: false,
        });
    }
    
    
    closeSortModal = () => {
        this.setState({
            sortModal: false,
        });
    }
    
    closeOperatorModal = () => {
        this.setState({
            operatorModalOpen: false,
        });
    }
    
    
}

const ListItem = (props) => {
    let imageSource = null;
    if(props.item.services_picture_uid) {
        imageSource = props.item['services_picture_uid_m'];
    }
    // props.dark ? '#dddddd' : '#eeeeee'
    return (
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>
        
            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
    
                <div style={{width: '100%', height: 300}}>
                    
                    <ImageFieldDisplay value={imageSource} full={props.item['services_picture_uid_o']} imageStyle={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}/>
                    
                </div>
                
                <div className={'internal-container'}>
    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        icon={'ft-package'}
                        value={props.item.supplier_name}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={(props.item.own ? props.item.own : 0) + ' ' + props.item.counter}
                        icon={'ft-layers'}
                        style={{marginBottom: 4}}/>
                        
                </div>
            
                <div className={'list-item-section-with-button'} style={{flex: 1}}>
                
                    <div className={'item-action-button'}>
                        <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                            {'Vezi piesa'}
                        </div>
                    </div>
            
                </div>
        
            </div>
    
        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(StockPage);