import {kResponseNegative} from './api';
import React from 'react';
import moment from 'moment';
import validator from 'validator';
import 'moment/locale/ro';
import {
    AttachMoneyRounded,
    BallotRounded,
    BusinessRounded,
    ContactsRounded,
    DashboardRounded,
    ExitToAppRounded,
    LoyaltyRounded,
    MapRounded,
    MenuRounded,
    PersonRounded,
    ReceiptRounded,
    ShoppingBasketRounded,
    StorageRounded,
    StoreRounded,
    ViewDayRounded,
} from '@material-ui/icons';

export const kDateFormatDisplay = 'Do MMMM YYYY';
export const kDateFormatServer = 'YYYY-MM-DD';

export const renderIf = (condition, element) => {
    if(condition) return element;
    else return null;
};

export const is = (object) => {
    if(object == null)  {
        return false;
    }
    if(typeof object == 'string' && object.length === 0) {
        return false;
    }
    if(typeof object == 'object' && object === {}) {
        return false;
    }
    
    return true;
};

export const updateObject = (object, updatedFields) => {
    return {
        ...object,
        ...updatedFields,
    };
};

export const responseType = (response) => {
    if(is(response) && response.hasOwnProperty('data')) {
        if (is(response.data) && response.data.hasOwnProperty('response')) {
            return response.data.response;
        }
    }
    return kResponseNegative;
};

export const isolateData = (response) => {
    if(response.data.hasOwnProperty('result')) {
        return response.data.result;
    }
    return null;
};

export const range = (from, to) => {
    let result = [];
    for(let i =from; i < to; ++i) {
        result.push(i);
    }
    return result;
};

export const filter = (list, fields, search, deepSearch = false) => {
    search = search + '';
    search = search.toLowerCase();

    if((search.replace(/ /g, '')).length > 0) {
        let reg = new RegExp(search, 'g');
        let result = [];
        let found = false;
        let currentElement;
        for (let i = 0; i < list.length; ++i) {
            found = false;
            for(let j = 0; j < fields.length; ++j) {
                currentElement = list[i];
                if(deepSearch) {
                    let elements = fields[j].split('.');
                    for(let k = 0; k < elements.length; ++k) {
                        if(currentElement.hasOwnProperty(elements[k])) {
                            currentElement = currentElement[elements[k]];
                        } else {
                            currentElement = null;
                            break;
                        }
                    }
                } else {
                    currentElement = currentElement[fields[j]];
                }
                if (currentElement && currentElement.toLowerCase().match(reg)) {
                    found = true;
                    break;
                }
            }
            if(found) {
                result.push(list[i]);
            }
        }
        return result;
    }
    return list;
};

export const prettyDate = (server) => {
    moment.locale('ro');
    return moment(server).format('DD MMM YYYY');
};

export const prettyMonthLocal = (local) => {
    moment.locale('ro');
    return moment(local, kDateFormatDisplay).format('MMMM YYYY');
};

export const prettyTime = (server) => {
    moment.locale('ro');
    return moment(server).format('HH:mm');
};

export const prettyTimeFromServer = (server) => {
    moment.locale('ro');
    return moment(server, "HH:mm:ss").format('HH:mm');
};

export const prettyDateAndTime = (server) => {
    return prettyDate(server) + ' ora ' + prettyTime(server);
};

export const prettyDuration = (server) => {
    moment.locale('ro');
    return server.humanize(true);
};

export const smallIcon = (icon, color, size, p_style) => {
    if(icon) {
        
        if(icon === 'blank') {
            return (
                <i style={{width: size, height: size}}/>
            );
        }
        
        let classname = icon;
        if(classname.startsWith('ft-')) {
            classname = 'feather ' + classname;
        }
    
        if(classname.startsWith('fa-')) {
            classname = 'fa ' + classname + ' fa-fw';
            //return faIcon(icon, size, color);
        }
        let style = {};
        if(size) {
            style.fontSize = size;
            style.width = size;
            style.height = size;
        }
    
        if(color) {
            style.color = color;
        }
    
        if(p_style) {
            style = {...style, ...p_style};
        }
        return (<i className={classname} style={style}/>);
    }
    
    return (null);
};

export const faIcon = (id, size, color) => {
    
    let style = {
    };
    
    if(size) {
        style.fontSize = size;
        style.width = size;
        style.height = size;
    }
    
    if(color) {
       style.color = color;
    }
    
    switch (id) {
        case 'fa-menu':
            return (
                <MenuRounded className={'ic'} style={style}/>
            );
        case 'fa-dashboard':
            return (
                <DashboardRounded className={'ic'} style={style}/>
            );
        case 'fa-tickets':
            return (
                <ViewDayRounded style={style}/>
            );
        case 'fa-sales':
            return (
                <LoyaltyRounded style={style}/>
            );
        case 'fa-invoices':
            return (
                <ReceiptRounded style={style}/>
            );
        case 'fa-clients':
            return (
                <BusinessRounded style={style}/>
            );
        case 'fa-calendar':
            return (
                <MapRounded style={style}/>
            );
        case 'fa-stocks':
            return (
                <StorageRounded style={style}/>
            );
        case 'fa-suppliers':
            return (
                <StoreRounded style={style}/>
            );
        case 'fa-operators':
            return (
                <ContactsRounded style={style}/>
            );
        case 'fa-necessary':
            return (
                <BallotRounded style={style}/>
            );
        case 'fa-orders':
            return (
                <ShoppingBasketRounded style={style}/>
            );
        case 'fa-account':
            return (
                <PersonRounded style={style}/>
            );
        
        case 'fa-logout':
            return (
                <ExitToAppRounded style={style}/>
            );
        
        case 'fa-price':
            return (
                <AttachMoneyRounded style={style}/>
            )
        default:
            break;
    }
    return (<div style={{width:size, height: size}}/>);
}

export const inputValidationAlwaysTrue = (toValidate) => {
    return true;
};

export const inputValidationSingleChoice = (toValidate) => {
    return toValidate != -1;
};

export const inputValidationNonEmpty = (toValidate) => {
    return !!toValidate && toValidate.replace(/ /g, '').length > 0;
};

export const inputValidationNonNull = (toValidate) => {
    return toValidate != null;
};

export const inputValidationEmail = (toValidate) => {
    return validator.isEmail(toValidate);
};

export const inputValidationPhone = (toValidate) => {
    return validator.isMobilePhone(toValidate, 'ro-RO');
};

export const inputValidationInteger = (toValidate) => {
    return validator.isInt(toValidate);
};

export const inputValidationIntegerMoreThan = (toValidate, number) => {
    return validator.isInt(toValidate) && (parseInt(toValidate) > number);
};

export const inputValidationYesSelected = (toValidate) => {
    return toValidate === 'yes';
};

export const inputValidationLengthNonZero = (toValidate) => {
    return toValidate.length > 0;
};

export const inputValidationSpecificTicketEquipments = (toValidate) => {
    return toValidate.length > 1 || inputValidationNonEmpty(toValidate[0].title);
};

export const inputValidationFloat = (toValidate) => {
    return validator.isFloat(toValidate + '');
};

export const inputValidationSpecificAllStockHasQuantity = (toValidate, quantity) => {
    for(let i=0; i<toValidate.length; ++i) {
        if(!quantity[toValidate[i]] || parseInt(quantity[toValidate[i]]) <= 0) {
            return false;
        }
    }
    return true;
};

export const inputValidationSpecificAllStockHasQuantityAndPrice = (toValidate, quantity, price) => {
    for(let i=0; i<toValidate.length; ++i) {
        if(!quantity[toValidate[i]] || parseInt(quantity[toValidate[i]]) <= 0 || !price[toValidate[i]] || parseInt(price[toValidate[i]]) <= 0) {
            return false;
        }
    }
    return true;
};

export const inputValidationSpecificAllStockHasQuantityAndPriceAndBounds = (toValidate, quantity, price) => {
    for(let i=0; i<toValidate.length; ++i) {
        if(!quantity[toValidate[i]] ||
            parseFloat(quantity[toValidate[i]]) <= 0 ||
            parseFloat(quantity[toValidate[i]]) > parseFloat(toValidate[i].total) ||
            !price[toValidate[i]] ||
            parseFloat(price[toValidate[i]]) <= 0) {
            return false;
        }
    }
    return true;
};

export const inputValidationStringNonEmpty = (toValidate) => {
    return !!toValidate && toValidate.replace(/ /g, '').length > 0;
};

export const inputValidationStringLengthBetween = (toValidate, min, max) => {
    return !!toValidate && toValidate.length >= min && toValidate.length <= max;
};

export const debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const trimString = (original, maxLen) => {
    if(!is(original) || typeof (original) !== 'string') {
        return '...';
    }
    if(original.length > maxLen) {
        original = original.slice(0, maxLen - 3) + '...';
    }
    return original;
}
