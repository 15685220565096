import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import {inputValidationNonEmpty, inputValidationSingleChoice} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';
import {getClient, sendEmail} from '../../functional/api';
import {kToastActionError, kToastActionNormal} from '../general/Toast';

const kFromEmails = [
    {
        value: 'dispecerat',
        label: 'dispecerat@polidom.ro',
        description: 'Adresa de email prestabilita pentru comunicarea cu clientii.'
    },
    {
        value: 'contabilitate',
        label: 'contabilitate@polidom.ro',
        description: 'Adresa de email specifica comunicarii despre datele contabile.'
    },
];

const kSignatureIds = [
    {
        value: 'echipa-polidom',
        label: 'Echipa Polidom',
        description: 'Semnatura prestabilita pentru comunicarea cu clientii.'
    },
];

const kSignatures = {
    'echipa-polidom': 'Va multumim,\nEchipa Polidom.'
};

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
        
        defaultData: PropTypes.object,
    };
    
    state = {

        subject: '',
        from: 'dispecerat',
        clientId: null,
        to: '',
        body: '',
        signatureId: null,
        signature: '',

        formComplete: false,
        
    };

    render () {

        return (
            <BaseModal
                title={'Trimite email'}
                stableHeight
                stableWidth
                ignoreKeyboard
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.from)}
         
                            icon={'ft-upload'}
                            label={'Trimite de la adresa'}
                            options={kFromEmails}
                            value={this.state.from}
                            style={{marginBottom: 4}}
                            onSelect={(value) => {
                                this.setState({
                                    from: value,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.clientId)}
        
                            icon={'ft-briefcase'}
                            label={'Catre clientul'}
                            predefinedModal={'client'}
                            value={this.state.clientId}
                            initialValue={true}
                            style={{marginBottom: 4}}
                            onSelect={(value, allData) => {
                                let toEmail = '';
                                if(allData.email) {
                                    toEmail = allData.email;
                                }
                                this.setState({
                                    clientId: value,
                                    to: toEmail,
                                });
                            }}/>
                            
                        {
                            this.state.clientId &&
                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.to)}
    
                                icon={'ft-at-sign'}
                                value={this.state.to}
                                style={{marginBottom: 4}}
                                label={'Pe email-ul'}
                                onChange={(newValue) => {this.setState({
                                    to: newValue,
                                });}}/>
                        }
                        
                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.subject)}

                            icon={'ft-disc'}
                            value={this.state.subject}
                            style={{marginBottom: 4}}
                            label={'Subiectul email-ului'}
                            onChange={(newValue) => {this.setState({
                                subject: newValue,
                            });}}/>
    
                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.body)}
        
                            textarea
                            iconAtStart
                            rows={12}
                            
                            icon={'ft-align-justify'}
                            value={this.state.body}
                            style={{marginBottom: 4}}
                            label={'Corpul email-ului'}
                            onChange={(newValue) => {this.setState({
                                body: newValue,
                            });}}/>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.signatureId)}
        
                            icon={'ft-edit'}
                            label={'Semnatura'}
                            options={kSignatureIds}
                            value={this.state.signatureId}
                            style={{marginBottom: 4}}
                            onSelect={(value) => {
                                console.log('value', value);
                                this.setState({
                                    signatureId: value,
                                    signature: kSignatures[value],
                                });
                            }}/>
                            
                        {
                            this.state.signatureId &&
                            <TextFieldInput
                                validated={inputValidationNonEmpty(this.state.signature)}
    
                                textarea
                                iconAtStart
                                rows={4}
                                locked
    
                                icon={'blank'}
                                value={this.state.signature}
                                style={{marginBottom: 4}}
                                onChange={(newValue) => {this.setState({
                                    signature: newValue,
                                });}}/>
                        }
                            
                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let request = {
            subject: this.state.subject,
            from: this.state.from,
            clientId: this.state.clientId,
            to: this.state.to,
            body: this.state.body,
            signature: this.state.signature,
        };
        
        this.props.openLoading();
        
        setTimeout(() => {
            this.props.closeLoading();
            this.clear();
            this.onClose();
            this.props.toast('Email-ul a fost trimis cu succes!', kToastActionNormal);
        }, 2000);

        /*sendEmail(request, (successful, data) => {
            if(successful) {
                this.props.closeLoading();
                this.onClose();
            } else {
                this.clear();
                this.props.toast('Operatiune esuata!', kToastActionError);
                this.props.closeLoading();
                this.onClose();
            }
        });*/
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if((!this.props.open && nextProps.open) || (this.props.defaultData !== nextProps.defaultData)) {
            this.update(nextProps);
        }
    }
    
    update = (props) => {
        if(props.defaultData) {
            if(props.defaultData.clientId) {
                this.setState({
                    clientId: props.defaultData.clientId,
                }, () => {
                    getClient(props.defaultData.clientId, (successful, client) => {
                        if(successful && client && client.email && client.id === this.state.clientId) {
                            this.setState({
                                to: client.email,
                            });
                        }
                    });
                });
            }
            if(props.defaultData.subject) {
                this.setState({
                    subject: props.defaultData.subject,
                });
            }
    
            if(props.defaultData.body) {
                this.setState({
                    body: props.defaultData.body,
                });
            }
    
            if(props.defaultData.signature) {
                this.setState({
                    signatureId: props.defaultData.signature,
                    signature: kSignatures[props.defaultData.signature],
                });
            }
        }
    };

    clear = () => {
        this.setState({
            subject: '',
            from: 'dispecerat',
            clientId: null,
            to: '',
            body: '',
            signatureId: '',
            signature: '',
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);