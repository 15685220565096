import { is } from './min-support';
import {
    verifyToken,
    login,
    createAccount,
    logout,
    kAPIEndpointSale,
    kAPIEndpointPVIPerson,
    kAPIEndpointPVI, kAPIEndpointInvoice, kAPIEndpointInvoicePerson, kAPIEndpointDeviz, kAPIEndpointEstimativ
} from './api';
import axios from 'axios';
import {deleteCredentials, deleteLoginUsername, getCredentials, saveCredentials, saveLoginUsername} from './storage';
import moment from 'moment';
import {colorByStatus, iconByStatus} from './status';

export const logicLogout = (callback) => {
    logout((result) => {
        // Don't really care;
    });
    deleteCredentials();
    callback(true);
}

export const isAuthenticated = (callback) => {
    let credentials = getCredentials();
    if(is(credentials.username) && is(credentials.token)) {
        verifyToken(credentials.token, credentials.username, callback);
    } else {
        callback(false);
    }
};

export const attemptAuthenticate = (username, password, callback) => {
    if(is(username) && is(password)) {
        login(username, password, (successful, token, username, data) => {
            if(successful) {
                saveCredentials(username, token);
                saveLoginUsername(username);
                callback(true, data);
            } else {
                callback(false);
            }
        });
    } else {
        callback(false);
    }
};

export const attemptCreateAccount = (firstName, lastName, username, password, callback) => {
    if(is(firstName) && is(lastName) && is(username) && is(password)) {
        createAccount(firstName, lastName, username, password, (successful, token, uid) => {
            if(successful) {
                saveCredentials(uid, token);
                callback(true);
            } else {
                callback(false);
            }
        });
    } else {
        callback(false);
    }
};

export const attemptLogout = (callback) => {
    logout((successful) => {if(!successful) console.log('Login not successful!');});

    deleteCredentials();

    callback();
};

let windowIndex = 0;

export const openPVI = (id) => {
    window.open(kAPIEndpointPVI + '?id=' + id, 'w' + (windowIndex ++), 'width=860,height=1111');
};

export const mincodeServicesGeneratePDF = (url, callback) => {
    const params = new URLSearchParams()
    params.append('source', url);
    params.append('provider', 'html/uri');
    params.append('mimetype', 'application/pdf');
    params.append('process', 'save');

    let config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'mincode-user': 'mincode-polidom-host-test',
            'mincode-password': 'token-token-token-token',
            'mincode-application': 'polidom-test',
            'mincode-class': 'pdf-generated-production',
        }
    }

    axios.post('https://services.devmincode.ro/media/protected/generate', params, config).then((result) => {
        if(is(result) && result.data.successful) {
            let data = result.data.data;
            if (data != null) {
                callback(true, data);
            } else {
                callback(false);
            }
        } else {
            callback(false);
        }
    }, (error) => {
        callback(false);
    });
};

export const openPVIServices = (id) => {
    mincodeServicesGeneratePDF(kAPIEndpointPVI + '?id=' + id, (successful, result) => {
        if(successful) {
            let pdfWindow = window.open(result.versions[0].extended);
        }
    });
}

export const openPVIServicesClientPerson = (id) => {
    mincodeServicesGeneratePDF(kAPIEndpointPVIPerson + '?id=' + id, (successful, result) => {
        if(successful) {
            let pdfWindow = window.open(result.versions[0].extended);
        }
    });
}

export const openSale = (id, username) => {
    window.open(kAPIEndpointSale + '?id=' + id + '&username=' + username, 'w' + (windowIndex ++), 'width=860,height=1111');
};

export const openInvoice = (id, username) => {
    mincodeServicesGeneratePDF(kAPIEndpointInvoice + '?id=' + id + '&username=' + username, (successful, result) => {
        if(successful) {
            let pdfWindow = window.open(result.versions[0].extended);
        }
    });
};

export const openInvoicePerson = (id, username) => {
    mincodeServicesGeneratePDF(kAPIEndpointInvoicePerson + '?id=' + id + '&username=' + username, (successful, result) => {
        if(successful) {
            let pdfWindow = window.open(result.versions[0].extended);
        }
    });
};

export const openDeviz = (id, username, loadId = -1) => {
    if(loadId >= 0) {
        window.open(kAPIEndpointDeviz + '?id=' + id + '&username=' + username + '&loadId=' + loadId + '&invalidate=' + new Date(), 'w' + (windowIndex ++), 'width=860,height=1111');
    } else {
        window.open(kAPIEndpointDeviz + '?id=' + id + '&username=' + username + '&invalidate=' + new Date(), 'w' + (windowIndex ++), 'width=860,height=1111');
    }
};

export const openEstimative = (id, username, loadId) => {
    mincodeServicesGeneratePDF(kAPIEndpointEstimativ + '?id=' + id + '&username=' + username + '&loadId=' + loadId + '&invalidate=' + new Date(), (successful, result) => {
        if(successful) {
            let pdfWindow = window.open(result.versions[0].extended);
        }
    });
};

export const translateAndMatchStockActionList = (actions) => {
    actions.sort((first, second) => {
        if(first.created > second.created) {
            return -1;
        } else if(first.created < second.created) {
            return 1;
        }
        return 0;
    });
    let result = [];
    let thisIsMatched = false;
    for(let i = 0; i < actions.length; ++i) {
        if(!thisIsMatched){
            let matchedWithNext = false;
            if(i !== actions.length-1) {
                if(actionsMatch(actions[i], actions[i+1])) {
                    matchedWithNext = true;
                    thisIsMatched = true;
                    let from = actions[i].destination;
                    let to = actions[i+1].destination;
                    let variation = actions[i+1].variation;
                    if(actions[i+1].variation.startsWith('-')) {
                        from = actions[i+1].destination;
                        to = actions[i].destination;
                        variation = actions[i].variation;
                    }
                    result.push({
                        ...actions[i],
                        id: actions[i].id,
                        icon: 'ft-repeat',
                        color: '#978107',
                        from: from,
                        to: to,
                        variation: variation,
                        'search_poster_name': actions[i].poster['first_name'] + ' ' + actions[i].poster['last_name'],
                        'search_from_name': from['first_name'] + ' ' + from['last_name'],
                        'search_to_name': to['first_name'] + ' ' + to['last_name'],
                    });
                }
            }
            if(!matchedWithNext) {
                let action = actions[i];
                let isOut = action.variation.startsWith('-');
                if(isOut) {
                    action.from = action.destination;
                } else {
                    action.to = action.destination;
                }
                action.icon = isOut ? 'ft-log-out' : 'ft-log-in';
                action.color = isOut ? '#741508' : '#105324';
                result.push(action);
            }
        } else {
            thisIsMatched = false;
        }
    }

    return result;
}

export const translateTicketActionList = (actions) => {
    actions.sort((first, second) => {
        if(first.created > second.created) {
            return -1;
        } else if(first.created < second.created) {
            return 1;
        }
        return 0;
    });
    let result = actions.map((action) => {return {
        ...action,
        key: action['id'],
        icon: iconByStatus(action['to_status']),
        color: colorByStatus(action['to_status']),
        status: action['to_status'],
        'search_first_name': action.user['first_name'],
        'search_last_name': action.user['last_name'],
    };})
    return result;
}

export const translateSaleProducts = (products) => {
    let result = products.map((product) => {return {
        ...product,
    };})
    return result;
}

export const translateClientHeadquarters = (headquarters) => {
    let result = headquarters.map((hq) => {return {
        ...hq,
    };})
    return result;
}

export const translateUserOperators = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateUserTickets = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateSupplierStock = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateSupplierOrders = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateOrderProducts = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateClientTickets = (items) => {
    let result = items.map((item) => {return {
        ...item,
    };})
    return result;
}

export const translateInvoiceEntries = (papers, sales) => {
    let result = [];
    result.push(...papers);
    result.push(...sales);
    return result;
}

export const translatePriority = (value) => {
    let result = '';
    if(value === 'low') {
        result = 'Prio 3';
    } else if(value === 'normal') {
        result = 'Prio 2';
    } else if(value === 'high') {
        result = 'Prio 1';
    } else if(value === 'urgent') {
        result = 'Urgent';
    }
    return result;
}


const actionsMatch = (first, second) => {
    if(first.description === second.description) {
        return true;
    }
    return false;
}



export const translateUser = (data) => {
    return {
        firstName: data['first_name'],
        lastName: data['last_name'],
        username: data.username,
        uid: data.uid,
        level: data.level,
        phone: data.phone,
        email: data.email,
        hasCustomLogo: data['services_logo_uid'],
        services_logo_uid: data['services_logo_uid'],
        services_logo_uid_o: data['services_logo_uid_o'],
        services_logo_uid_m: data['services_logo_uid_m'],
        services_logo_uid_s: data['services_logo_uid_s'],
        isPerson: data.username === 'polidom_persoane_fizice' || data.username === 'contabilitate_persoane_fizice',
    };
};