import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


import {snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import LoadingComponent from "../general/LoadingAnimation";

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,

        toast: PropTypes.func,
    };
    
    state = {

    };

    render () {

        return (
            <BaseModal
                title={''}
                open={this.props.open}
                hideDone
                hideClose
                onClose={this.onClose}
                doneActive={false}>

                <div className={'w-100 h-100'} style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center'}}>

                    <LoadingComponent extended />

                </div>

            </BaseModal>
        );
    }

    onClose = () => {

    };

    componentWillReceiveProps(nextProps, nextContext) {

    }
}

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
        updateModalInfo: state.updateModalInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);