import React, {Component} from 'react';
import PageError from '../../components/general/PageError';

class LogoutPage extends Component {
    
    render() {
        return (
            <React.Fragment>
                <PageError
                    art={'•200•'}
                    title={"O zi faina!"}
                    message={'Deautentificare a fost realizata cu succes!'}
                    button={{title: 'Inapoi la Autentificare', link: '/login'}}/>
            </React.Fragment>
        )
    }
    
}

export default LogoutPage;