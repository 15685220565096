import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllOperatorsAndLocations} from '../functional/api';
import {connect} from 'react-redux';
import moment from 'moment';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {prettyDuration} from '../functional/min-support';
import InternalToolbar from '../components/general/InternalToolbar';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";
import {kTimeoutDurationForPersistentStateRetrieval} from "../functional/constants";

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={defaultPosition}
        center={props.position}>
        {
            props.markers && props.markers.length > 0 &&
            props.markers.map((marker) => {
                if(marker) {
                    return (
                        <Marker labelStyle={{fontFamily: 'Roboto', color: '#333', backgroundColor: '#eee', padding: 10}}
                                label={marker.label} position={{lat: marker.latitude, lng: marker.longitude}}/>
                    );
                } else {
                    return null;
                }
            })
        }
    </GoogleMap>
))


const defaultPosition = {lat: 47.151591, lng: 27.587520};

class MapPage extends PersistentStateComponent {

    identifier = 0;

    updateInterval = null;

    static propTypes = {
        user: PropTypes.object,
    };

    static defaultState = {
        mapPosition: defaultPosition,
        search: '',

        operators: [],
    }

    state = MapPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-map-page';
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateInterval = setInterval(this.update, 5000);
    }

    componentWillUnmount() {
        if(this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    generalUpdate = () => {
        this.setState(MapPage.defaultState, this.update);
    }

    update = () => {
        getAllOperatorsAndLocations((successful, data) => {
            if(successful) {
                data = data.sort((a, b) => {
                    if(a.location && !b.location) {
                        return -1;
                    } else if(!a.location && b.location) {
                        return 1;
                    } else if(a.location && b.location) {
                        return a.location.time > b.location.time ? -1 : (a.location.time < b.location.time ? 1 : 0);
                    } else {
                        return 0;
                    }
                })
                this.setState({
                    operators: data,
                });
            }
        });
    }

    render() {
        return(
            <div className="w-100 h-100 d-flex flex-column overflow-hidden">
                <InternalToolbar
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    searchValue={this.state.search}/>
                <div className="w-100 flex-grow-1 d-flex flex-row align-items-center overflow-hidden">
                    <div className="h-100 flex-grow-1 pt-4 pb-4 ps-4">
                        <MyMapComponent
                            position={this.state.mapPosition}
                            markers={this.getMarkers()}

                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZ0EAMt169I--nsSnlR8WN8VLxfZ1i6YI&libraries=geometry,drawing,places"
                            loadingElement={
                                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                    <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                            }
                            containerElement={<div style={{ height: `100%`, width: '100%', borderRadius: 8, boxShadow: '1px 0px 20px rgba(0, 0, 0, 0.08)' }} />}
                            mapElement={<div style={{ height: `100%`, width: '100%', borderRadius: 8 }} />}/>
                    </div>

                    <div className="h-100 overflow-scroll" style={{width: 340, padding: 20, overflowY: 'scroll'}}>
                        {
                            this.state.operators.map((item) => {
                                return (<ItemEntry item={item} onPress={this.onItemPress}/>);
                            })
                        }

                        {
                            (!this.state.operators || this.state.operators.length <= 0) &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    getMarkers = () => {
        return this.state.operators.map((operator) => {
            if(operator.location && operator.location.longitude && operator.location.latitude) {
                return {
                    longitude: parseFloat(operator.location.longitude),
                    latitude: parseFloat(operator.location.latitude),
                    label: operator['first_name'] + ' ' + operator['last_name']
                };
            }
            return null;
        });
    }

    onItemPress = (item) => {
        let location = item.location;
        if(location && location.latitude && location.longitude) {
            this.setState({
                mapPosition: {
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                }
            });
        }
    }
}

const ItemEntry = (props) => {
    let functional = props.item.location && props.item.location.longitude && props.item.location.latitude && props.item.location.time;
    let active = false;
    let difference = '. . .'
    if(functional) {
        let actDifference = moment.duration(moment(new Date()).diff(moment(props.item.location.time)));
        if(actDifference.asMinutes() <= 10) {
            active = true;
        }
        difference = prettyDuration(actDifference);
    }
    let color = active ? '#cceecc' : '#eeeecc';
    return (
        <div style={{width: '100%', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, marginBottom: 10, backgroundColor: color, borderRadius: 8}}>

            <TextFieldDisplay
                value={props.item['first_name'] + ' ' + props.item['last_name']}
                style={{marginBottom: 4}}/>

            <TextFieldDisplay
                value={props.item.username}
                icon={'ft-users'}
                style={{marginBottom: 4}}/>

            <TextFieldDisplay
                icon={'ft-code'}
                label={'status locatie'}
                value={active ? 'Activ' : 'Inactiv'}
                style={{marginBottom: 4}}/>

            {
                functional &&
                <TextFieldDisplay
                    icon={'ft-clock'}
                    label={'ultima actualizare in urma cu'}
                    value={difference}
                    style={{marginBottom: 4}}/>
            }

            {
                functional &&
                <TextFieldDisplay
                    icon={'ft-eye'}
                    value={'vezi ultima locatie'}
                    action={'ft-eye'}
                    onPress={() => {
                        props.onPress(props.item);
                    }}/>
            }

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(MapPage);