import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllMail, getAllTickets} from '../functional/api';
import ListPage from '../layout/page/ListPage';
import {
    kFilterMailFields,
    kFilterMailFieldsDefault,
    kFilterTicketFields,
    kFilterTicketFieldsDefault, kTimeoutDurationForPersistentStateRetrieval
} from '../functional/constants';
import AddTicketModal from '../components/modal/AddTicketModal';
import {withRouter} from 'react-router-dom';
import {toTicketPage} from '../functional/navigation';

import ListItem from '../components/mail/MailListItem';
import AddMailModal from '../components/modal/AddMailModal';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";


class MailPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = MailPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-mail-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(MailPage.defaultState, this.update);
    }
    
    update = () => {
        getAllMail((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                deepSearch={true}
                loading={this.state.items == null}
                actions={[{
                    title: 'Scrie email',
                    icon: {title: 'ft-send'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                defaultFields={kFilterMailFieldsDefault}
                allFields={kFilterMailFields}>

                <AddMailModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        //toTicketPage(item.id);
    }
    
}

export default withRouter(MailPage);