import React, { Component } from 'react';
import { PolarArea } from 'react-chartjs-2';

class PolarChart extends Component {

    render() {
        const data = {
            labels: ["Informatie 1", "Informatie 2", "Informatie 3", "Informatie 3"],
            datasets: [{
                backgroundColor: [
                    "#443c3c",
                    "#312f2f",
                    "#d63735",
                    "#A92B2A",
                    "#6c1b1b",
                ],
                data: [12, 19, 3, 17]
            }]
        };

        const option = {
            maintainAspectRatio: false,

            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },
        }

        return (
            <React.Fragment>
                <PolarArea width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default PolarChart;   