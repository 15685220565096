import React from 'react';

class PersistentStateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.persistentExcludedState = [];
        this.persistentIdentifier = 'polidom-persistent-state-component';
    }

    componentDidMount() {
        let saved = window.localStorage.getItem(this.persistentIdentifier);
        if(saved) {
            saved = JSON.parse(saved);
            this.setState({...this.state, ...saved});
        }
    }

    componentWillUnmount() {
        let toSaveState = {...this.state};
        this.persistentExcludedState.forEach((item) => {
            if(toSaveState[item]) {
                delete toSaveState[item];
            }
        });
        window.localStorage.setItem(this.persistentIdentifier, JSON.stringify(toSaveState));
    }
}

export default PersistentStateComponent;