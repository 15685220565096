import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getBriefListOperatorsForStock, getStockSingle} from '../functional/api';
import {openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import TimelineList from '../components/lists/TimelineList';
import {filter, prettyDateAndTime} from '../functional/min-support';
import {translateAndMatchStockActionList} from '../functional/logic';
import AddStockActionModal from '../components/modal/AddStockActionModal';

class StockItemPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        search: '',
        item: null,
        operators: null,
        
        modalOpen: false,
        changePictureModal: false,
    };
    
    state = StockItemPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
        if(this.props.history.location.state && this.props.history.location.state.search){
            this.setState({
                search: this.props.history.location.state.search,
            });
        }
    }
    
    generalUpdate = () => {
        this.setState(StockItemPage.defaultState, this.update);
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getStockSingle(key, (successful, data) => {
            if(successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
        getBriefListOperatorsForStock(key, (successful, data) => {
            if(successful) {
                this.setState({
                    operators: data,
                });
            }
        });
    };
    
    render () {
        let translatedActions = this.state.item && this.state.item.actions ? translateAndMatchStockActionList(this.state.item.actions) : [];
        let toolbarActions = [
            {
                title: 'Schimba poza',
                onPress: () => {this.setState({changePictureModal: true});},
                icon: {title: 'ft-image'}
            },
            {
                title: 'Adauga actiune',
                onPress: () => {this.setState({modalOpen: true});},
                icon: {title: 'ft-plus'}
            },
        ];
        let toolbarLabels = [
            {
                connection: 'se cauta operatorii dupa:',
                tags: ['Numele utilizatorului', 'Prenumele utilizatorului']
            },
            {
                connection: 'si actiunile dupa:',
                tags: ['Numele operatorului', 'Numele sursei', 'Numele destinatiei']
            }
        ];
        let operators = this.state.operators;
        if(operators && this.state.search.length > 0) {
            operators = filter(operators, ['label'], this.state.search, false);
        }
        if(translatedActions && this.state.search.length > 0) {
            translatedActions = filter(translatedActions, ['search_poster_name', 'search_from_name', 'search_to_name'], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
        
                    <div className="h-100 overflow-scroll" style={{flex: 1, padding: 20}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{marginBottom: 4}}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'stock',
                                                'name');
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'furnizat de'}
                                        value={this.state.item.supplier ? this.state.item.supplier.name : '...'}
                                        icon={'ft-package'}
                                        action={'ft-eye'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            if(this.state.item.supplier && this.state.item.supplier.id) {
                                                this.props.history.replace('/supplier/' + this.state.item.supplier.id);
                                            }
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'modelul'}
                                        value={this.state.item.model}
                                        icon={'ft-cpu'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.model,
                                                'stock',
                                                'model');
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'seria'}
                                        value={this.state.item.series}
                                        icon={'ft-hash'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.series,
                                                'stock',
                                                'series');
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'pozitia'}
                                        value={this.state.item.placement}
                                        icon={'ft-map-pin'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.placement,
                                                'stock',
                                                'placement');
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'pretul de baza'}
                                        value={this.state.item.price}
                                        icon={'ft-dollar-sign'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.price,
                                                'stock',
                                                'price');
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'tipul cantitatii'}
                                        value={this.state.item['counter']}
                                        icon={'ft-droplet'}
                                        style={{marginBottom: 4}}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.setState({
                                                newQuantityTypeModal: true,
                                            });
                                        }}/>
        
                                    <TextFieldDisplay
                                        label={'stocul tau'}
                                        value={this.state.item['own'] + " " + this.state.item['counter']}
                                        icon={'ft-layers'}
                                        style={{marginBottom: 4}}/>
    
                                </div>
                            </React.Fragment>
                        }
    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
    
                        {
                            operators &&
                            <div className="w-100 d-flex flex-row flex-wrap" style={{paddingTop: 10}}>
                                {
                                    operators.map((operator) => {
                                        return (
                                            <div style={{padding: 10, width: '50%', paddingBottom: 0}}>
                                                <TextFieldDisplay
                                                    oneLine
                                                    value={operator.label}
                                                    icon={'ft-chevrons-right'}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
        
                    <div className="h-100 overflow-scroll" style={{width: '30%', padding: 20, paddingLeft: 0}}>
                        
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'} style={{padding: 0, height: 400}}>
                                    <ImageFieldDisplay
                                        value={this.state.item['services_picture_uid_m']}
                                        full={this.state.item['services_picture_uid_o']}/>
                                </div>
                                <TimelineList
                                    items={translatedActions}
                                    renderItem={(item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{paddingLeft: 20}}>
                                                <TextFieldDisplay
                                                    style={{marginBottom: 4}}
                                                    value={item.description}
                                                    icon={'ft-file-text'}/>
    
                                                <TextFieldDisplay
                                                    style={{marginBottom: 4}}
                                                    value={prettyDateAndTime(item.created)}
                                                    icon={'ft-clock'}/>
    
                                                <TextFieldDisplay
                                                    value={'numar bucati: ' + item.variation + ''}
                                                    icon={'ft-layers'}
                                                    style={{marginBottom: 4}}/>
                                                    
                                                {
                                                    item.from &&
                                                    <TextFieldDisplay
                                                        label={'din stocul utilizatorului'}
                                                        value={item.from['first_name'] + ' ' + item.from['last_name']}
                                                        icon={'ft-corner-left-down'}
                                                        style={{marginBottom: 4}}/>
                                                }
                                                
                                                {
                                                    item.to &&
                                                    <TextFieldDisplay
                                                        label={'catre stocul utilizatorului'}
                                                        value={item.to['first_name'] + ' ' + item.to['last_name']}
                                                        icon={'ft-corner-down-right'}
                                                        style={{marginBottom: 4}}/>
                                                }
    
                                                <TextFieldDisplay
                                                    label={'inregistrat de'}
                                                    value={item.poster['first_name'] + ' ' + item.poster['last_name']}
                                                    icon={'ft-users'}/>
                                            </div>
                                        );
                                    }}/>
                            </React.Fragment>
                        }
    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
        
                    </div>
                </div>
    
                {
                    this.state.item &&

                    <AddStockActionModal
                        parentKey={this.state.item.id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>
                        
                }
                
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockItemPage));