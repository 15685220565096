import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllOperatorsArranged} from '../functional/api';
import {withRouter} from 'react-router-dom';
import {kFilterOperatorFields, kFilterOperatorFieldsDefault} from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import AddOperatorModal from '../components/modal/AddOperatorModal';
import {toUserPage} from '../functional/navigation';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class UsersPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = UsersPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-users-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(UsersPage.defaultState, this.update);
    }
    
    update = () => {
        getAllOperatorsArranged((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no entries');
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items == null}
                actions={[{
                    title: 'Adauga utilizator',
                    icon: {title: 'ft-plus'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterOperatorFieldsDefault}
                allFields={kFilterOperatorFields}>

                <AddOperatorModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toUserPage(item.id);
    }
    
}

export const getLevelNameBasedOnLevel = (level) => {
    if(level == '2') {
        return 'Cont de management';
    } else if(level == '3') {
        return 'Cont de tehnician';
    } else if(level == '-2') {
        return 'Cont pentru depozit';
    }
    return 'neidentificat';
};

const ListItem = (props) => {
    return(
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>
            
            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
                
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item['first_name'] + ' ' + props.item['last_name']}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.email}
                        icon={'ft-at-sign'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.phone}
                        icon={'ft-phone'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.username}
                        icon={'ft-lock'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={getLevelNameBasedOnLevel(props.item.level)}
                        icon={'ft-layers'}
                        style={{marginBottom: 4}}/>
                </div>
                
                <div className={'item-action-button'}>
                    <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                        {'Vezi utilizatorul'}
                    </div>
                </div>
                
            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(UsersPage);