import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import Modals from '../components/modal/Modals';
import Toast from '../components/general/Toast';

class Layout extends Component {
    render() {
        let pathname = window.location.pathname.replace('/', '');
        return (
            <React.Fragment>
                <div className="page-container">
                    
                    <Sidebar />
                    <Modals />
                    <Toast />
                    
                    <div className={(pathname === 'blank') ? 'is_blank_page main-content' : 'main-content'} id="main_content_sec">
                        <Header />
                        <div className="main-content-inner">
                            {this.props.children}
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        );
    }
}

// <!--<Footer />--> // after children div

export default withRouter(Layout);
