import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {addOperator} from '../../functional/api';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import {
    inputValidationEmail,
    inputValidationNonEmpty,
    inputValidationPhone,
    inputValidationSingleChoice,
    renderIf
} from '../../functional/min-support';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        type: 'operator',
        
        company: '',
        cif: '',
        reg: '',
        address: '',

        formComplete: false,
    };

    render () {

        return (
            <BaseModal
                title={'Adauga utilizator'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.firstName)}

                            icon={'name'}
                            value={this.state.firstName}
                            style={{marginBottom: 4}}
                            label={'Prenume utilizator'}
                            onChange={(newValue) => {this.setState({
                                firstName: newValue,
                            });}}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.lastName)}

                            icon={'name'}
                            value={this.state.lastName}
                            style={{marginBottom: 4}}
                            label={'Nume utilizator'}
                            onChange={(newValue) => {this.setState({
                                lastName: newValue,
                            });}}/>

                        {
                            renderIf(this.props.user.level == 1,
                                <SingleChoiceField
                                    required
                                    validated={inputValidationSingleChoice(this.state.type)}

                                    icon={'name'}
                                    label={'Tipul utilizatorului'}
                                    options={[
                                        {
                                            label: 'Operator direct subordonat',
                                            description: 'operatori care lucreaza direct pentru acest punct de lucru',
                                            value: 'operator',
                                        },
                                        {
                                            label: 'Manager / partener',
                                            description: 'poate crea proprii operatori pentru punctul de lucru / firma partenera',
                                            value: 'manager',
                                        },
                                    ]}
                                    value={this.state.type}
                                    style={{marginBottom: 4}}
                                    onSelect={(value)=>{this.setState({
                                        type: value,
                                    });}}/>
                            )
                        }
                        
                        {
                            this.state.type === 'manager' &&
                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.company)}
    
                                icon={'client'}
                                value={this.state.company}
                                style={{marginBottom: 4}}
                                label={'Nume companie'}
                                onChange={(newValue) => {this.setState({
                                    company: newValue,
                                });}}/>
                        }
    
                        {
                            this.state.type === 'manager' &&
                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.cif)}
            
                                icon={'client'}
                                value={this.state.cif}
                                style={{marginBottom: 4}}
                                label={'Cod identificare fiscala'}
                                onChange={(newValue) => {this.setState({
                                    cif: newValue,
                                });}}/>
                        }
    
                        {
                            this.state.type === 'manager' &&
                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.reg)}
            
                                icon={'client'}
                                value={this.state.reg}
                                style={{marginBottom: 4}}
                                label={'Nr. registrul comertului'}
                                onChange={(newValue) => {this.setState({
                                    reg: newValue,
                                });}}/>
                        }
    
                        {
                            this.state.type === 'manager' &&
                            <TextFieldInput
                                required
                                validated={inputValidationNonEmpty(this.state.address)}
            
                                icon={'client'}
                                value={this.state.address}
                                style={{marginBottom: 4}}
                                label={'Adresa sediu social'}
                                onChange={(newValue) => {this.setState({
                                    address: newValue,
                                });}}/>
                        }

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.username)}

                            icon={'lock'}
                            value={this.state.username}
                            style={{marginBottom: 4}}
                            label={'Nume autentificare'}
                            onChange={(newValue) => {this.setState({
                                username: newValue,
                            });}}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.password)}

                            icon={'lock'}
                            value={this.state.password}
                            style={{marginBottom: 4}}
                            label={'Parola autentificare'}
                            onChange={(newValue) => {this.setState({
                                password: newValue,
                            });}}/>

                        <TextFieldInput
                            required
                            validated={inputValidationEmail(this.state.email)}

                            icon={'email'}
                            value={this.state.email}
                            style={{marginBottom: 4}}
                            label={'Email utilizator'}
                            onChange={(newValue) => {this.setState({
                                email: newValue,
                            });}}/>

                        <TextFieldInput
                            required
                            validated={inputValidationPhone(this.state.phone)}

                            icon={'phone'}
                            value={this.state.phone}
                            style={{marginBottom: 4}}
                            label={'Telefon utilizator'}
                            onChange={(newValue) => {this.setState({
                                phone: newValue,
                            });}}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let request = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            type: this.state.type,
            company: this.state.company,
            cif: this.state.cif,
            reg: this.state.reg,
            address: this.state.address,
        };

        this.props.openLoading();

        addOperator(request, (successful) => {
            if(successful) {
                this.clear();
                this.props.toast('Operator inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    get firstNameSelected () {
        return this.state.firstName != '';
    }

    get lastNameSelected () {
        return this.state.lastName != '';
    }

    get usernameSelected () {
        return this.state.username != '';
    }

    get passwordSelected () {
        return this.state.password.length > 3;
    }

    get readyToSend() {
        return this.firstNameSelected && this.lastNameSelected && this.usernameSelected && this.passwordSelected;
    }

    clear = () => {
        this.setState({
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            
            reg: '',
            cif: '',
            address: '',
            company: '',
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);