import {
    routeClients,
    routeDashboard,
    routeInvoices,
    routeMap,
    routeNecessary,
    routeUsers,
    routeOrders,
    routeReports,
    routeSales,
    routeStock,
    routeSuppliers,
    routeTickets, routeMail,
} from './routes';

const sidebarRoutes = [
    routeDashboard,
    routeTickets,
    routeMail,
    routeSales,
    routeInvoices,
    routeClients,
    {
        title: 'Stoc',
        icon: 'ft-layers',
        children: [routeStock, routeSuppliers, routeNecessary, routeOrders],
    },
    {
        title: 'Operatori',
        icon: 'ft-users',
        children: [routeUsers, routeMap],
    },
    {
        title: 'Rapoarte',
        icon: 'ft-bar-chart',
        children: [routeReports],
    },
];

export default sidebarRoutes;