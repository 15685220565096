import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';

class RadarChart extends Component {


    render() {
        const data = {
            labels: ["Informatie 1", "Informatie 2", "Informatie 3", "Informatie 4"],
            datasets: [{
                label: 'Principale',
                backgroundColor: "#312f2f",
                borderColor: "#443c3c",
                data: [12, 19, 3, 17],
                borderWidth: 2
            }, {
                label: 'De baza',
                backgroundColor: "#6c1b1b",
                borderColor: "#d63735",
                data: [30, 29, 5, 5, 20, 3, 10],
                borderWidth: 2
            }]
        };

        const option = {
            maintainAspectRatio: false,

            legend: {
                display: true,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                }
            },
        }

        return (
            <React.Fragment>
                <Radar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default RadarChart;   