import axios from 'axios';
import {isolateData, responseType} from './min-support';
import {getCredentials} from './storage';

export const kResponsePositive = 'response-ok';
export const kResponseNegative = 'response-negative';

const kAPIHost = 'https://api.test.polidom.ro';

export const kAPIEndpointPVI = kAPIHost + '/file/pvi.php';
export const kAPIEndpointPVIPerson = kAPIHost + '/file/pvi_person.php';
export const kAPIEndpointSale = kAPIHost + '/file/aviz.php';
export const kAPIEndpointInvoice = kAPIHost + '/file/factura.php';
export const kAPIEndpointInvoicePerson = kAPIHost + '/file/factura_person.php';
export const kAPIEndpointDeviz = kAPIHost + '/file/deviz.php';
export const kAPIEndpointEstimativ = kAPIHost + '/file/estimativ.php';
export const kAPIEndpointPaperModern = kAPIHost + '/file/paper_modern.php';

export const verifyToken = (callback) => {
    protectedRequest(kAPIHost + '/credentials/verify-token.php', {}, callback);
};

export const login = (username, password, callback) => {
    publicRequest(kAPIHost + '/credentials/login.php', {username, password}, (successful, data) => {
        if(successful) {
            callback(successful, data.token, data.username, data);
        } else {
            callback(successful);
        }
    });
};

export const logout = (callback) => {
    protectedRequest(kAPIHost + '/credentials/logout.php', {}, callback);
};

export const createAccount = (firstName, lastName, username, password, callback) => {
    publicRequest(kAPIHost + '/credentials/create-account.php', {firstName: firstName, lastName: lastName, username: username, password: password}, (successful, data) => {
        if(successful) {
            callback(successful, data.token, data.uid);
        } else {
            callback(successful);
        }
    });
};

export const getAccountInfo = (callback) => {
    protectedRequest(kAPIHost + '/common/get-user-self.php', {}, callback);
};

export const getClient = (clientId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-client.php', {clientId}, callback);
};

export const getClientByCif = (cif, callback) => {
    protectedRequest(kAPIHost + '/info/get-client-by-cif.php', {cif}, callback);
};

export const getNecessaryItem = (necessaryId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-necessary-item.php', {necessaryId}, callback);
};

export const getStockSingle = (stockId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-stock-single.php', {stockId}, callback);
};

export const getOperator = (operatorId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-operator.php', {operatorId}, callback);
};

export const getSupplier = (supplierId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-supplier.php', {supplierId}, callback);
};

export const getTicket = (ticketId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-item.php', {ticketId}, callback);
};

export const getAddActionInfo = (ticketId, callback) => {
    protectedRequest(kAPIHost + '/info/add-action.php', {ticketId}, callback);
};

export const getBriefListClients = (callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-clients.php', {}, callback);
};

export const getBriefListSuppliers = (callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-suppliers.php', {}, callback);
};

export const getBriefListOperators = (callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-operators.php', {}, callback);
};

export const getBriefListOperatorsForStockExtended = (stockId, callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-operators-for-stock.php', {stockId, extended: true}, callback);
};

export const getBriefListManagers = (stockId, callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-managers.php', {stockId, extended: true}, callback);
};

export const getBriefListOperatorsForStock = (stockId, callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-operators-for-stock.php', {stockId}, callback);
};

export const getBriefListHeadquarters = (clientId, callback) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-headquarters.php', {clientId}, callback);
};

export const getAddTicketInfo = (callback) => {
    protectedRequest(kAPIHost + '/info/add-ticket.php', {}, callback);
};

export const checkClientAssigned = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-assigned-client-id.php', {}, callback);
};

export const getAddStockInfo = (callback) => {
    protectedRequest(kAPIHost + '/info/add-stock.php', {}, callback);
};

export const getStockBySupplier = (supplierId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-stock-by-supplier.php', {supplierId}, callback);
};

export const getNumber = (type, callback) => {
    protectedRequest(kAPIHost + '/misc/get-number.php', {type}, callback);
};

export const getAllClients = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-clients.php', {}, callback);
};

export const getAllEquipments = (headquartersId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-equipments-for-headquarters.php', {headquartersId}, callback);
};

export const acceptNewUser = (userId, callback) => {
    protectedRequest(kAPIHost + '/misc/accept-user.php', {userId}, callback);
};

export const rejectNewUser = (userId, callback) => {
    protectedRequest(kAPIHost + '/misc/reject-user.php', {userId}, callback);
};

export const getPendingUsers = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-pending-users.php', {}, callback);
};

export const getAllNecessaryItems = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-necessary-items.php', {}, callback);
};

export const getUserLevel = (id, callback) => {
    protectedRequest(kAPIHost + '/misc/get-level.php', {id}, callback);
};

export const getAllStock = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-stock.php', {}, callback);
};

export const getPaperData = (id, callback) => {
    protectedRequest(kAPIHost + '/misc/get-paper-data.php', {ticketId: id}, callback);
};

export const getStockOwn = (callback, extended = false) => {
    protectedRequest(kAPIHost + '/misc/get-stock-own.php', {}, callback);
};

export const getBriefListStock = (callback, extended = false) => {
    protectedRequest(kAPIHost + '/misc/list/brief-list-stock.php', {extended}, callback);
};

export const getAllStockModern = (suppliers, operators, search, offset, limit, sortType, callback) => {
    protectedRequest(kAPIHost + '/misc/get-stock.php', {operators, suppliers, sortType}, callback);
};

export const getAllOperators = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-operators.php', {}, callback);
};

export const getAllOperatorsAdvanced = (date, headquartersId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-operators-advanced.php', {date, headquartersId}, callback);
};

export const getAllOperatorsArranged = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-operators-arranged.php', {}, callback);
};

export const getAllOperatorsAndLocations = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-operators-and-locations.php', {}, callback);
};

export const getOrder = (orderId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-order.php', {orderId}, callback);
};

export const getSale = (saleId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-sale.php', {saleId}, callback);
};

export const getInvoice = (invoiceId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-invoice.php', {invoiceId}, callback);
};

export const orderArrived = (orderId, callback) => {
    protectedRequest(kAPIHost + '/misc/order-arrived.php', {orderId}, callback);
};

export const getAllOrders = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-orders.php', {}, callback);
};

export const getAllSales = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-sales.php', {}, callback);
};

export const getAllSalesForClient = (clientId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-sales-for-client.php', {clientId}, callback);
};

export const getAllPapersForInvoice = (clientId, callback) => {
    protectedRequest(kAPIHost + '/misc/get-papers-for-invoice.php', {clientId}, callback);
};

export const getAllInvoices = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-invoices.php', {}, callback);
};

export const getAllSuppliers = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-suppliers.php', {}, callback);
};

export const getAllTickets = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-items.php', {}, callback);
};

export const getAllMail = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-all-mail.php', {}, callback);
};

export const getLastActions = (callback) => {
    protectedRequest(kAPIHost + '/misc/get-actions.php', {}, callback);
};

export const addTransaction = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-transaction.php', data, callback);
};

export const addClient = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-client.php', data, callback);
};

export const clearAccountImage = (callback) => {
    protectedRequest(kAPIHost + '/misc/delete-account-image.php', {}, callback);
};

export const addOperator = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-operator.php', data, callback);
};

export const removeOperatorSessions = (operatorUsername, callback) => {
    protectedRequest(kAPIHost + '/misc/remove-operator-session.php', {operatorUsername}, callback);
};

export const addAction = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-action.php', data, callback);
};

export const update = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/update.php', data, callback);
};

export const addTicket = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-ticket.php', data, callback);
};

export const addTicketFromSubmit = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-ticket-from-submit.php', data, callback);
};

export const addStock = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-stock.php', data, callback);
};

export const addEquipment = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-equipment.php', data, callback);
};

export const sendEmail = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/send-email.php', data, callback);
};

export const addOrder = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-order.php', data, callback);
};

export const addSale = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-sale.php', data, callback);
};

export const addEmailAddress = (emailAddress, callback) => {
    protectedRequest(kAPIHost + '/misc/add-email-address.php', {emailAddress: emailAddress}, callback);
};

export const addEstimative = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-estimative.php', data, callback);
};

export const addPaperModern = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-paper-modern.php', data, callback);
};

export const addInvoice = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-invoice.php', data, callback);
};

export const addStockAction = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-stock-action.php', data, callback);
};

export const addHeadquarters = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-headquarters.php', data, callback);
};

export const addSupplier = (data, callback) => {
    protectedRequest(kAPIHost + '/misc/add-supplier.php', data, callback);
};

export const filterCategory = (search, callback) => {
    protectedRequest(kAPIHost + '/misc/filter-category.php', {search}, callback);
};

// UPLOAD

export const uploadManagerLogoFile = (file, fileName, callback) => {
    let credentialsContainer = {data: {}, credentials: getCredentials()};
    let formData = new FormData();
    formData.append('image', file, fileName);
    formData.append('credentials', JSON.stringify(credentialsContainer.credentials));
    axios.post(kAPIHost + '/misc/upload-manager-logo.php', formData).then((result) => {
        console.warn('upload_resp', result);
        if(responseType(result) === kResponsePositive) {
            let data = isolateData(result);
            if (data != null) {
                callback(true, data);
            } else {
                callback(false);
            }
        } else {
            callback(false);
        }
    }, (error) => {
        console.warn('upload_err', error);
        callback(false);
    });
};

export const uploadStockPhoto = (file, stockId, callback) => {
    let credentialsContainer = {data: {stockId}, credentials: getCredentials()};
    let formData = new FormData();
    formData.append('image', file, file.name);
    formData.append('credentials', JSON.stringify(credentialsContainer.credentials));
    formData.append('data', JSON.stringify(credentialsContainer.data));
    axios.post(kAPIHost + '/misc/add-stock-image.php', formData).then((result) => {
        if(responseType(result) === kResponsePositive) {
            let data = isolateData(result);
            if (data != null) {
                callback(true, data);
            } else {
                callback(false);
            }
        } else {
            callback(false);
        }
    }, (error) => {
        console.warn('upload_err', error);
        callback(false);
    });
};

const publicRequest = (endpoint, requestData, callback, debugRawResult = null, debugRawError = null) => {
    let requestDataWithoutCredentials = {data: requestData, credentials: {}};
    axios.post(endpoint, requestDataWithoutCredentials).then((result) => {
        if(debugRawResult) {
            debugRawResult(result);
        }
        if(responseType(result) === kResponsePositive) {
            let data = isolateData(result);
            callback(true, data);
        } else {
            callback(false);
        }
    }, (error) => {
        if(debugRawError) {
            debugRawError(error);
        }
        callback(false);
    });
}

const protectedRequest = (endpoint, requestData, callback, debugRawResult = null, debugRawError = null) => {
    let credentials = getCredentials();
    if(!credentials.username || !credentials.token) {
        callback(false);
        if(debugRawResult) {
            debugRawResult("No credentials saved...");
        }
        if(debugRawError) {
            debugRawError("No credentials saved...");
        }
        return;
    }
    let requestDataWithCredentials = {data: requestData, credentials: credentials};
    axios.post(endpoint, requestDataWithCredentials).then((result) => {
        if(debugRawResult) {
            debugRawResult(result);
        }
        if(responseType(result) === kResponsePositive) {
            let data = isolateData(result);
            callback(true, data);
        } else {
            callback(false);
        }
    }, (error) => {
        if(debugRawError) {
            debugRawError(error);
        }
        callback(false);
    });
}