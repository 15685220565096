import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {
    addEstimative, addPaperModern,
    getBriefListStock,
    getPaperData,
    getStockOwn,
    kAPIEndpointEstimativ, kAPIEndpointPaperModern,
    kAPIEndpointPVI
} from '../../functional/api';
import {
    closeLoadingModal,
    openLoadingModal,
    openPDFViewModal,
    snackAdd
} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import {inputValidationSpecificAllStockHasQuantityAndPrice} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';
import {mincodeServicesGeneratePDF} from '../../functional/logic';
import SimpleButton from '../input/SimpleButton';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,
        
        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
        
        key: PropTypes.any,
    };
    
    state = {
        initialInfo: null,
        clientId: -1,
        stock: [],
        stockSelected: [],
        stockQuantity: {},
        stockPrice: {},
        
        name: '',
        
        saleType: 'client',
        
        workHours: '',
        workCost: '',
        
        addedFields: [],
        
        formComplete: false,
    };
    
    componentDidMount() {
        this.initialRetrieve();
    }
    
    render() {
        if(!this.state.initialInfo) {
            return (
                <BaseModal
                    title={'Genereaza deviz'}
                    stableHeight
                    open={this.props.open}
                    doneActive={this.state.formComplete}
                    onClose={this.onClose}
                    onDone={this.onDone}>
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                </BaseModal>
            )
        }
        return (
            <BaseModal
                title={'Genereaza deviz'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>
                
                <div className={'w-100'}>
                    
                    <Form complete={this.state.formComplete} setComplete={(complete) => {
                        this.setState({formComplete: complete});
                    }}>
    
                        <div
                            style={{
                                width: '100%',
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 2,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{flex: 1}}
                                label={'Deviz numarul'}
                                value={'PDD ' + (20000 + parseInt(this.state.initialInfo.ticket.id))}
                                icon={'ft-file-plus'}/>
        
                            <TextFieldDisplay
                                style={{flex: 1, paddingLeft:4}}
                                label={'Tipul interventiei'}
                                value={this.state.initialInfo.ticket.type}/>
    
                        </div>
    
                        <div
                            style={{
                                width: '100%',
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 2,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{flex: 1}}
                                label={'Denumirea clientului'}
                                value={this.state.initialInfo.ticket.client.name}
                                icon={'ft-briefcase'}/>
        
                            <TextFieldDisplay
                                style={{flex: 1, paddingLeft:4}}
                                label={'Denumirea beneficiarului'}
                                value={this.state.initialInfo.ticket.beneficiary}/>
    
                        </div>
    
                        <div
                            style={{
                                width: '100%',
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 2,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{flex: 1}}
                                label={'Data receptiei'}
                                value={this.state.initialInfo.startDate}
                                icon={'ft-calendar'}/>
        
                            <TextFieldDisplay
                                style={{flex: 1, paddingLeft:4}}
                                label={'Data reparatiei'}
                                value={this.state.initialInfo.finishDate}/>
    
                        </div>
    
                        <div
                            style={{
                                width: '100%',
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 2,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{flex: 1}}
                                label={'La punctul de lucru de la adresa ' + this.state.initialInfo.ticket.client.headquarters.name}
                                value={'Pe parcursul lucrarilor s-au efectuat ' + this.state.initialInfo.nbOfPvi + ' procese verbale'}
                                icon={'ft-file'}/>
    
                        </div>
    
                        <div
                            style={{
                                width: '100%',
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                flex: 5,
                                borderRadius: 4,
                            }}>
        
                            <TextFieldDisplay
                                style={{flex: 3}}
                                label={'Manopera'}
                                value={'Ore lucrate de tehnician'}
                                icon={'ft-watch'}/>
        
                            <TextFieldInput
                                style={{flex: 1, paddingLeft:4}}
                                label={'Numar de ore'}
                                value={this.state.workHours}
                                onChange={(newValue) => {
                                    this.setState({workHours: newValue})
                                }}/>
        
                            <TextFieldInput
                                style={{flex: 1, paddingLeft:4}}
                                label={'Cost per ora (RON)'}
                                value={this.state.workCost}
                                onChange={(newValue) => {
                                    this.setState({workCost: newValue})
                                }}/>
    
                        </div>
                        
                        {
                            this.state.addedFields.map((item, index) => {
                                return(
                                    <div
                                        style={{
                                            width: '100%',
                                            marginBottom: 10,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            flex: 5,
                                            borderRadius: 4,
                                        }}>
    
                                        <TextFieldInput
                                            flex={1}
                                            style={{flex: 4}}
                                            label={'Denumire serviciu'}
                                            icon={'ft-chevrons-right'}
                                            value={item.name}
                                            onChange={(newValue) => {
                                                let addedFields = this.state.addedFields;
                                                addedFields[index].name = newValue;
                                                this.setState({addedFields: addedFields})
                                            }}/>
        
                                        <TextFieldInput
                                            flex={1}
                                            style={{flex: 1, paddingLeft:4}}
                                            label={'Cost (RON)'}
                                            value={item.cost}
                                            onChange={(newValue) => {
                                                let addedFields = this.state.addedFields;
                                                addedFields[index].cost = newValue;
                                                this.setState({addedFields: addedFields})
                                            }}/>
    
                                    </div>
                                )
                            })
                        }
    
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: 10}}>
                            <SimpleButton value={'Adauga un alt serviciu'} onPress={this.addField} icon={'ft-plus'}/>
                        </div>
    
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={true} // inputValidationLengthNonZero(this.state.stockSelected)
                            search
                            
                            minimumChoices={0}
                            maximumChoices={100}
                            
                            icon={'ft-settings'}
                            label={'Apasa pentru a modifica piesele incluse'}
                            options={this.state.stock}
                            values={this.state.stockSelected}
                            style={{marginBottom: 4}}
                            onSelect={(values) => {
                                this.setState({
                                    stockSelected: values,
                                });
                            }}/>
                        
                        <div
                            requireCompletion
                            required
                            validated={inputValidationSpecificAllStockHasQuantityAndPrice(this.state.stockSelected, this.state.stockQuantity, this.state.stockPrice)}>
                            {
                                this.state.stockSelected.map((item) => {
                                    let found = this.state.stock.filter((i) => i.value === item);
                                    if (found.length > 0) {
                                        found = found[0];
                                        return (
                                            <StockEntry
                                                item={found}
                                                onChange={(value, newCount, newPrice) => {
                                                    let q = this.state.stockQuantity;
                                                    q[value] = newCount;
                                                    
                                                    let p = this.state.stockPrice;
                                                    p[value] = newPrice;
                                                    
                                                    this.setState({
                                                        stockQuantity: q,
                                                        stockPrice: p,
                                                    });
                                                }}
                                                onDelete={(value) => {
                                                    let v = this.state.stockSelected;
                                                    v = v.filter(item => item !== value);
                                                    this.setState({
                                                        stockSelected: v,
                                                    });
                                                }}
                                                counter={found.counter}
                                                maxQuantity={found.total}
                                                quantity={this.state.stockQuantity.hasOwnProperty(found.value) ? this.state.stockQuantity[found.value] : ''}
                                                price={this.state.stockPrice.hasOwnProperty(found.value) ? this.state.stockPrice[found.value] : ''}
                                            />
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>
                    
                    </Form>
                
                </div>
            
            </BaseModal>
        );
    }
    
    addField = () => {
        let addedFields = this.state.addedFields;
        addedFields.push({name: '', cost: ''});
        this.setState({
            addedFields: addedFields,
        });
    }
    
    onClose = () => {
        this.props.onClose();
    };
    
    onDone = () => {
        let selected = this.state.stockSelected;
        let stock = [];
        for (let i = 0; i < selected.length; ++i) {
            stock.push({
                count: this.state.stockQuantity[selected[i]],
                price: this.state.stockPrice[selected[i]],
                value: selected[i],
            });
        }
        
        let request = {
            stock: stock,
            ticketId: this.props.parentKey,
            workHours: this.state.workHours,
            workCost: this.state.workCost,
            others: this.state.addedFields,
        };
        
        this.props.openLoading();
        
        addPaperModern(request, (successful, result) => {
            if (successful) {
                this.clear();
                this.props.toast('Deviz inregistrat cu succes.');
                let loadId = result;
     
                this.props.openPDFViewModal('loading');
                console.log('link', kAPIEndpointPaperModern + '?id=' + this.props.parentKey + '&username=' + this.props.user.username + '&loadId=' + loadId);
                mincodeServicesGeneratePDF(kAPIEndpointPaperModern + '?id=' + this.props.parentKey + '&username=' + this.props.user.username + '&loadId=' + loadId, (successful, result) => {
                    if(successful) {
                        this.props.openPDFViewModal(result.versions[0].extended);
                    }
                });
                
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };
    
    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    initialRetrieve = () => {
        getPaperData(this.props.parentKey, (successful, data) => {
            if(successful) {
                this.setState({
                    initialInfo: data,
                });

                let workHours = ((data.totalMinutes) / 60.0).toFixed(0);
                this.setState({
                    workHours: workHours,
                });

                let work_price = data.ticket.work_price;
                this.setState({
                    workCost: work_price,
                });
                
                if(data.utilised && data.utilised.length > 0) {
                    let utilised = [];
                    let quant = {};
                    let prices = {};
                    data.utilised.map((item) => {
                        utilised.push(item.id);
                        quant[item.id] = item.nb;
                        prices[item.id] = item.price;
                        return null;
                    });
                    this.setState({
                        stockSelected: utilised,
                        stockQuantity: quant,
                        stockPrice: prices,
                    }, () => {
                        this.update();
                    });
                }
            } else {
                console.log('initialInfoError');
            }
        });
    }
    
    update = () => {
        getBriefListStock((successful, data) => {
            if (successful) {
                this.setState({
                    stock: data.map(item => {
                        return {
                            value: item.value,
                            label: item.label,
                            description: 'Pretul din baza de date: ' + item.price + ' RON',
                            counter: item.counter,
                            price: item.price,
                        };
                    }),
                });
            } else {
                console.error('no add action info');
            }
        });
        /*getStockOwn((successful, data) => {
            if (successful) {
                this.setState({
                    stock: data.map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                            description: 'Ai in stoc: ' + item.total + ' ' + item.counter + '. Pretul din baza de date: ' + item.price + ' RON',
                            total: item.total,
                            counter: item.counter,
                            price: item.price,
                        };
                    }),
                });
            } else {
                console.error('no add action info');
            }
        });*/
    };
    
    clear = () => {
        this.setState({
            clientId: -1,
            stock: [],
            stockSelected: [],
            stockQuantity: {},
            stockPrice: {},
            name: '',
            saleType: 'client',
        });
    }
    
}

const StockEntry = (props) => {
    return (
        <div
            style={{
                width: '100%',
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                flex: 5,
                borderRadius: 4,
            }}>
            
            <TextFieldDisplay
                style={{flex: 4}}
                label={'Denumirea piesei'}
                value={props.item.label}
                icon={'ft-chevron-right'}/>
            
            <TextFieldInput
                flex={1}
                style={{flex: 1, paddingLeft:4}}
                label={'Numar ' + props.counter}
                value={props.quantity}
                onChange={(newValue) => {
                    props.onChange(props.item.value, newValue, props.price)
                }}/>
            
            <TextFieldInput
                flex={1}
                style={{flex: 1, paddingLeft:4}}
                label={'Pret per U.M. (RON)'}
                value={props.price}
                onChange={(newValue) => {
                    props.onChange(props.item.value, props.quantity, newValue)
                }}/>
    
            <SimpleButton value={'Sterge'} onPress={() => {props.onDelete(props.item.value)}} style={{marginLeft: 4}}/>

        </div>
    );
};

StockEntry.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),
        
        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    
        openPDFViewModal: (link) => dispatch(openPDFViewModal(link)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);