
export const kInterventionTypes = [
    {
        label: 'Punere in functiune',
        value: 'Punere in functiune',
    },
    {
        label: 'Garantie',
        value: 'Garantie',
    },
    {
        label: 'Mentenanta',
        value: 'Mentenanta',
    },
    {
        label: 'Lucrari la cerere',
        value: 'Lucrari la cerere',
    },
    {
        label: 'Interventie tehnica',
        value: 'Interventie tehnica',
    },
];

export const kWarrantyFirms = [
    {
        label: 'Electronics Suport Division',
        value: 'Electronics Suport Division',
    },
    {
        label: 'Depanero',
        value: 'Depanero',
    },
    {
        label: 'Alf Electrocasnice',
        value: 'Alf Electrocasnice',
    },
    {
        label: 'Welness Solutions',
        value: 'Welness Solutions',
    },
    {
        label: 'Electrolux Romania',
        value: 'Electrolux Romania',
    },
    {
        label: 'Micadan Comexim',
        value: 'Micadan Comexim',
    },
    {
        label: 'CTS-Cardinal Top Systems',
        value: 'CTS-Cardinal Top Systems',
    },
    {
        label: 'BSH Electrocasnice',
        value: 'BSH Electrocasnice',
    },
    {
        label: 'Welness Solutions Inc SRL',
        value: 'Welness Solutions Inc SRL',
    },
];

export const kQuantityTypes = [
    {
        label: 'Bucati',
        value: 'bucati',
    },
    {
        label: 'Metri',
        value: 'metri',
    },
    {
        label: 'Kilograme',
        value: 'kg',
    },
    {
        label: 'Litri',
        value: 'litri',
    },
]



export const kFilterTicketFields = [
    {
        value: 'name',
        label: 'Numarul tichetului',
    },
    {
        value: 'status',
        label: 'Statusul tichetului',
    },
    {
        value: 'description',
        label: 'Descrierea generala a tichetului',
    },
    {
        value: 'created',
        label: 'Data si ora receptionarii',
    },
    {
        value: 'type',
        label: 'Tipul interventiei',
    },
    {
        value: 'client.name',
        label: 'Numele clientului',
    },
    {
        value: 'beneficiary',
        label: 'Numele beneficiarului',
    },
    {
        value: 'client.headquarters.address',
        label: 'Adresa punctului de lucru',
    },
];
export const kFilterTicketFieldsDefault = ['name', 'status', 'description'];

export const kFilterMailFields = [
    {
        value: 'fromAddress',
        label: 'Adresa sursei',
    },
    {
        value: 'fromName',
        label: 'Numele sursei',
    },
    {
        value: 'subject',
        label: 'Subiectul mesajului',
    },
    {
        value: 'text',
        label: 'Continutul mesajului',
    },
];
export const kFilterMailFieldsDefault = ['fromAddress', 'subject'];

export const kFilterClientFields = [
    {
        value: 'name',
        label: 'Denumirea clientului',
    },
    {
        value: 'cif',
        label: 'Codul de identificare fiscala',
    },
    {
        value: 'reg',
        label: 'Nr. inregistrare reg. comert',
    },
    {
        value: 'bank',
        label: 'Banca',
    },
    {
        value: 'iban',
        label: 'IBAN cont bancar',
    },
    {
        value: 'address',
        label: 'Adresa sediului social',
    },
];
export const kFilterClientFieldsDefault = ['name'];

export const kFilterSaleFields = [
    {
        value: 'name',
        label: 'Numarul avizului',
    },
];
export const kFilterSaleFieldsDefault = ['name'];

export const kFilterInvoiceFields = [
    {
        value: 'name',
        label: 'Numarul facturii',
    },
    {
        value: 'client_name',
        label: 'Numele clientului',
    },
    {
        value: 'client_cif',
        label: 'Codul fiscal al clientului',
    },
    {
        value: 'client_reg',
        label: 'Numarul de inregistrare al clientului',
    },
];
export const kFilterInvoiceFieldsDefault = ['name', 'client_name'];

export const kFilterStockFields = [
    {
        value: 'name',
        label: 'Denumirea piesei',
    },
    {
        value: 'model',
        label: 'Modelul piesei',
    },
    {
        value: 'series',
        label: 'Seria piesei',
    },
];
export const kFilterStockFieldsDefault = ['name'];

export const kFilterSupplierFields = [
    {
        value: 'name',
        label: 'Denumirea furnizorului',
    },
    {
        value: 'cif',
        label: 'Codul de identificare fiscala',
    },
    {
        value: 'reg',
        label: 'Nr. inregistrare reg. comert',
    },
    {
        value: 'bank',
        label: 'Banca',
    },
    {
        value: 'iban',
        label: 'IBAN cont bancar',
    },
    {
        value: 'address',
        label: 'Adresa sediului social',
    },
];
export const kFilterSupplierFieldsDefault = ['name'];

export const kFilterOperatorFields = [
    {
        value: 'first_name',
        label: 'Prenumele utilizatorului',
    },
    {
        value: 'last_name',
        label: 'Numele utilizatorului',
    },
    {
        value: 'username',
        label: 'Nume autentificare',
    },
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'phone',
        label: 'Numar telefon',
    },
];
export const kFilterOperatorFieldsDefault = ['first_name', 'last_name', 'username'];

export const kFilterNecessaryFields = [
    {
        value: 'name',
        label: 'Denumirea piesei',
    },
];
export const kFilterNecessaryFieldsDefault = ['name'];

export const kFilterOrderFields = [
    {
        value: 'name',
        label: 'Numarul comenzii',
    },
    {
        value: 'supplier_name',
        label: 'Denumirea furnizorului',
    }
];
export const kFilterOrderFieldsDefault = ['name', 'supplier_name'];

export const kTimeoutDurationForPersistentStateRetrieval = 500;