import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {addHeadquarters} from '../../functional/api';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import {inputValidationNonEmpty} from '../../functional/min-support';
import Form from '../input/Form';
import LocationPickerModal from './model/LocationPickerModal';

const defaultPosition = {
    lat: 47.159809,
    lng: 27.587200
};

class Modal extends Component {
    
    static propTypes = {
        parentKey: PropTypes.any,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        name: '',
        address: '',
        email: '',
        position: defaultPosition,
        
        addressPickerModal: false,

        formComplete: false,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(!this.props.open && nextProps.open) {
            this.update();
        }
    }

    update = () => {

    };

    render () {

        return (
            <BaseModal
                title={'Adauga sediu'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.name)}

                            value={this.state.name}
                            style={{marginBottom: 4}}
                            label={'Nume punct de lucru'}
                            onChange={(newValue) => {this.setState({
                                name: newValue,
                            });}}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.address)}
                            locked

                            icon={'client'}
                            value={this.state.address}
                            style={{marginBottom: 4}}
                            label={'Adresa punct de lucru'}
                            onPress={() => {
                                this.setState({
                                    addressPickerModal: true,
                                });
                            }}/>
    
                        <TextFieldInput
                            icon={'email'}
                            value={this.state.email}
                            style={{marginBottom: 0}}
                            label={'Email punct de lucru (pentru notificari automate)'}
                            onChange={(newValue) => {
                                this.setState({
                                    email: newValue,
                                });
                            }}/>

                    </Form>
                    
                    <LocationPickerModal
                        open={this.state.addressPickerModal}
                        title={'Alege locatia punctului de lucru'}
                        onClose={() => {
                            this.setState({
                                addressPickerModal: false,
                            });
                        }}
                        onDone={(newAddress, newPosition) => {
                            this.setState({
                                address: newAddress,
                                position: newPosition,
                                addressPickerModal: false,
                            });
                        }}
                        initialAddress={this.state.address}
                        initialPosition={this.state.position}/>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if(!this.props.parentKey) {
            return;
        }

        let request = {
            clientId: this.props.parentKey,
            name: this.state.name,
            address: this.state.address,
            position: this.state.position,
            email: this.state.email,
        };

        this.props.openLoading();

        addHeadquarters(request, (successful) => {
            if(successful) {
                this.clear();
                this.props.toast('Punct de lucru inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    get nameSelected() {
        return this.state.name != '';
    }

    get addressSelected() {
        return this.state.address != '';
    }

    get readyToSend() {
        return this.nameSelected && this.addressSelected;
    }

    clear = () => {
        this.setState({
            name: '',
            address: '',
            position: defaultPosition,
            email: '',
            addressPickerModal: false,
        });
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(null, mapDispatchToProps)(Modal);