import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {addSupplier} from '../../functional/api';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import {inputValidationNonEmpty} from "../../functional/min-support";
import Form from "../input/Form";

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        name: '',
        cif: '',
        reg: '',
        bank: '',
        iban: '',
        address: '',

        formComplete: false,
    };

    render () {

        return (
            <BaseModal
                title={'Adauga furnizor'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.name)}

                            value={this.state.name}
                            style={{marginBottom: 4}}
                            label={'Nume firma furnizor'}
                            onChange={(newValue) => {this.setState({
                                name: newValue,
                            });}}/>

                        <TextFieldInput
                            icon={'code'}
                            value={this.state.cif}
                            style={{marginBottom: 4}}
                            label={'Cod identificare fiscala'}
                            onChange={(newValue) => {this.setState({
                                cif: newValue,
                            });}}/>

                        <TextFieldInput
                            icon={'code'}
                            value={this.state.reg}
                            style={{marginBottom: 4}}
                            label={'Nr. inregistrare registrul comertului'}
                            onChange={(newValue) => {this.setState({
                                reg: newValue,
                            });}}/>

                        <TextFieldInput
                            icon={'bank'}
                            value={this.state.bank}
                            style={{marginBottom: 4}}
                            label={'Banca furnizor'}
                            onChange={(newValue) => {this.setState({
                                bank: newValue,
                            });}}/>

                        <TextFieldInput
                            icon={'bank'}
                            value={this.state.iban}
                            style={{marginBottom: 4}}
                            label={'IBAN furnizor'}
                            onChange={(newValue) => {this.setState({
                                iban: newValue,
                            });}}/>

                        <TextFieldInput
                            icon={'client'}
                            value={this.state.address}
                            style={{marginBottom: 4}}
                            label={'Adresa sediu social'}
                            onChange={(newValue) => {this.setState({
                                address: newValue,
                            });}}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let request = {
            name: this.state.name,
            cif: this.state.cif,
            reg: this.state.reg,
            bank: this.state.bank,
            iban: this.state.iban,
            address: this.state.address,
        };

        this.props.openLoading();

        addSupplier(request, (successful) => {
            if(successful) {
                this.clear();
                this.props.toast('Furnizor inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    get nameSelected() {
        return this.state.name != '';
    }

    get cifSelected() {
        return this.state.cif != '';
    }

    get regSelected() {
        return this.state.reg != '';
    }

    get bankSelected() {
        return this.state.bank != '';
    }

    get ibanSelected() {
        return this.state.iban != '';
    }

    get addressSelected() {
        return this.state.address != '';
    }

    get readyToSend() {
        return this.nameSelected;
    }

    clear = () => {
        this.setState({
            name: '',
            cif: '',
            reg: '',
            bank: '',
            iban: '',
            address: '',
        });
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(null, mapDispatchToProps)(Modal);