import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';

import mobile_logo from "../assets/images/mobile-logo.png";
import maleImage from "../assets/images/global/user-placeholder-male.png";
import femaleImage from "../assets/images/global/user-placeholder-female.png";
import {logicLogout} from '../functional/logic';
import {setAuthenticated, toggleDrawer} from '../store/actions/application-store-actions';
import {connect} from 'react-redux';
import routes from '../functional/routes';
import {toUserPage} from '../functional/navigation';

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            create_menu: false,
            toggle: false,
            menu: false,
            notificationMenu: false,
            messagesMenu: false,
        };
        this.toggleCreate = this.toggleCreate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.toggleMessages = this.toggleMessages.bind(this);
    }
    
    searchInRoutes = (received) => {
        let found = routes.filter((route) => route.id === received);
        if(found.length > 0) {
            return found[0];
        }
        return null;
    }
    
    contextualPage = (lastRoute, position) => {
        if(position === 1) {
            return this.props.itemPageTitle;
        }
        return 'Necunoscut';
    }
    
    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    toggleNotification() {
        this.setState(prevState => ({
            notificationMenu: !prevState.notificationMenu
        }));
    }

    toggleMessages() {
        this.setState(prevState => ({
            messagesMenu: !prevState.messagesMenu
        }));
    }

    toggleCreate() {
        this.setState(prevState => ({
            create_menu: !prevState.create_menu
        }));
    }

    sidebarToggle = () => {
        const pageClass = document.querySelector('.page-container');
        pageClass.classList.toggle('sidebar_collapsed');
        this.props.onToggleDrawer();
    }

    toggleFullscreen() {
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
    
    componentDidMount() {
        setTimeout(() => {
            this.sidebarToggle();
        }, 400);
    }
    
    render() {
        let currentPathname = this.props.location.pathname;
        let routes = currentPathname.split('/').filter((token) => token.length !== 0);
        let combinedRoute = '';
        let lastRoute = null;
        let breadcrumbs = routes.map((route, position) => {
            combinedRoute += '/' + route;
            let foundRoute = this.searchInRoutes(route);
            let title = foundRoute ? foundRoute.title : this.contextualPage(lastRoute, position);
            lastRoute = foundRoute;
            return {
                title: title,
                path: combinedRoute,
            };
        });
        
        return (
            <React.Fragment>
                <div className="header-area">
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-grow-1 align-items-center">
                            <div className="nav-btn pull-left" onClick={this.sidebarToggle}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
        
                            <div className="rt_subheader_main">
                                <div className="rt_breadcrumb">
                                    <Link to="/dashboard" className="rt_breadcrumb_home_icon"><span className={'breadcrumb_link'} style={{paddingRight: 4}}>Polidom Dashboard</span><i className="feather ft-home"/></Link>
                                    {
                                        breadcrumbs.map((breadcrumb) => {
                                            return (
                                                <>
                                                    <span className="rt_breadcrumb_separator"><i className="feather ft-chevrons-right"/></span>
                                                    <Link to={breadcrumb.path} className="breadcrumb_link pl-4">{breadcrumb.title}</Link>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
    
                        <ul className="notification-area">
                            <li id="full-view" className="d_none_sm" onClick={this.toggleFullscreen}><i className="feather ft-maximize"></i></li>
        
                            <Dropdown isOpen={this.state.notificationMenu} toggle={this.toggleNotification} tag="li">
                                <DropdownToggle tag="i" className="ti-bell">
                                    <span></span>
                                </DropdownToggle>
            
                                <DropdownMenu className="dropdown-menu bell-notify-box notify-box">
                                    <span className="notify-title">Ai 3 notificari noi</span>
                                    <Scrollbars style={{ height: "350px" }}>
                                        <div className="nofity-list">
                                            <DropdownItem tag="a" htef="#" className="notify-item">
                                                <div className="notify-thumb"><i className="ti-panel bg_success"></i></div>
                                                <div className="notify-text">
                                                    <h3>Pregatire sistem notificari</h3>
                                                    <span>Acum 2 zile</span>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem tag="a" htef="#" className="notify-item">
                                                <div className="notify-thumb"><i className="ti-comments bg_info"></i></div>
                                                <div className="notify-text">
                                                    <h3>Ai adaugat o actiune</h3>
                                                    <span>Acum 4 zile</span>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem tag="a" htef="#" className="notify-item">
                                                <div className="notify-thumb"><i className="ti-settings bg_secondary"></i></div>
                                                <div className="notify-text">
                                                    <h3>Contul tau a fost creat</h3>
                                                    <span>Acum 14 zile</span>
                                                </div>
                                            </DropdownItem>
                                        </div>
                
                                    </Scrollbars>
                                </DropdownMenu>
                            </Dropdown>
        
                            <Dropdown isOpen={this.state.messagesMenu} toggle={this.toggleMessages} tag="li">
                                <DropdownToggle tag="i" className="fa fa-envelope-o">
                                    <span></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu notify-box nt-enveloper-box">
                                    <span className="notify-title">Ai 2 mesaje noi</span>
                                    <Scrollbars style={{ height: "350px" }}>
                                        <div className="nofity-list">
                                            <DropdownItem tag="a" htef="#" className="notify-item">
                                                <div className="notify-thumb">
                                                    <img src={maleImage} alt="image1" />
                                                </div>
                                                <div className="notify-text">
                                                    <h3>Sistem</h3>
                                                    <span className="msg">Acest element grafic vine in <br/> pregatirea sistemului de <br/> mesagerie intre operatori<br/> si dispecer</span>
                                                    <span>04 Iunie 13:15</span>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem tag="a" htef="#" className="notify-item">
                                                <div className="notify-thumb">
                                                    <img src={femaleImage} alt="image1" />
                                                </div>
                                                <div className="notify-text">
                                                    <h3>Contabilitate</h3>
                                                    <span className="msg">Test mesaj factura</span>
                                                    <span>04 Iunie 10:30</span>
                                                </div>
                                            </DropdownItem>
                                        </div>
                
                                    </Scrollbars>
                                </DropdownMenu>
                            </Dropdown>
        
                            <li className="user-dropdown">
                                <Dropdown isOpen={this.state.menu} toggle={this.toggle} tag="div" >
                                    <DropdownToggle className="btn dropdown-toggle d-flex align-items-center" tag="div">
                                        <span className="d_none_sm">{this.props.user ? this.props.user.firstName + ' ' + this.props.user.lastName : 'WTF'}<i className="ti-angle-down"/></span>
                                        <i className="feather ft-user" style={{width: 32, height: 32, fontSize: 32, marginRight: 10, borderRadius: 100}}/>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu">
    
                                        <DropdownItem tag="a" href="#" onClick={() => {
                                            toUserPage('self');
                                        }}><i className="ti-user"/> Profilul tau</DropdownItem>
    
                                        <span role="separator" className="divider"/>
                    
                                        <DropdownItem tag="a" className="text-danger" href="#" onClick={() => {
                                            logicLogout((successful) => {
                                                if(successful) {
                                                    this.props.onSetAuthenticated(false);
                                                    this.props.history.push('/logout');
                                                }
                                            });
                                        }}>
                                            <i className="ti-power-off"/> Iesi din cont
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/*
  
  <img src={this.props.user && this.props.user.gender === 'male' ? maleImage : femaleImage} alt="" className="img-fluid" />

 */

/*

<DropdownItem tag="a" href="#"><i className="ti-user"/> Profilul tau</DropdownItem>
<DropdownItem tag="a" href="#"><i className="ti-settings"/> Setarile contului</DropdownItem>
<span role="separator" className="divider"/>

 */

const mapStateToProps = (state) => {
    return {
        user: state.user,
        drawerOpen: state.drawerOpen,
        itemPageTitle: state.itemPageTitle,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAuthenticated: (value, info=null) => dispatch(setAuthenticated(value, info)),
        onToggleDrawer: () => dispatch(toggleDrawer()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));