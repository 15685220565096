import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {acceptNewUser, getAllClients, getPendingUsers, rejectNewUser} from '../functional/api';
import {withRouter} from 'react-router-dom';
import {
    kFilterClientFields,
    kFilterClientFieldsDefault,
    kTimeoutDurationForPersistentStateRetrieval
} from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import AddClientModal from '../components/modal/AddClientModal';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../store/actions/application-store-actions';
import {connect} from 'react-redux';
import {kToastActionError} from '../components/general/Toast';
import {toClientPage} from '../functional/navigation';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class ClientsPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    
        pendingUsers: [],
    }
    
    state = ClientsPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items', 'pendingUsers'];
        this.persistentIdentifier = 'pps-clients-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
        this.updatePendingUsers();
    }
    
    updatePendingUsers = () => {
        getPendingUsers((successful, data) => {
            if(successful) {
                this.setState({
                    pendingUsers: data,
                });
            } else {
                console.error('no pending users');
            }
        })
    }
    
    generalUpdate = () => {
        this.setState(ClientsPage.defaultState, this.update);
    }
    
    update = () => {
        getAllClients((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };
    
    render () {
        return (
            <ListPage
                grid
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items == null}
                actions={[{
                    title: 'Adauga client',
                    icon: {title: 'ft-plus'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                renderHeaderElement={this.renderPendingUser}
                headerElements={this.state.pendingUsers}

                defaultFields={kFilterClientFieldsDefault}
                allFields={kFilterClientFields}>

                <AddClientModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    renderPendingUser = (item) => {
        return (
            <div className={'HeaderElementItem'}>
                <TextFieldDisplay
                    oneLine
                    label={'numele clientului'}
                    value={item.company}
                    style={{marginBottom: 4}}/>
    
                <TextFieldDisplay
                    oneLine
                    label={'cod identificare fiscala'}
                    value={item.cif}
                    icon={'blank'}
                    style={{marginBottom: 4}}/>
    
                <TextFieldDisplay
                    oneLine
                    label={'nr. inregistrare reg. comert'}
                    value={item.reg}
                    icon={'blank'}
                    style={{marginBottom: 4}}/>
    
                <TextFieldDisplay
                    oneLine
                    label={'email'}
                    icon={'blank'}
                    value={item.username}
                    style={{marginBottom: 4}}/>
    
                <TextFieldDisplay
                    oneLine
                    label={'telefon'}
                    value={item.phone}
                    icon={'blank'}
                    style={{marginBottom: 4}}/>
                    
                <div className={'buttons-container'}>
                    <div className={'button-left'} onClick={() => {
                        this.onClientUserAccept(item);
                    }}>
                        {"Accepta"}
                    </div>
                    
                    <div className={'button-right'} onClick={() => {
                        this.onClientUserAccept(item);
                    }}>
                        {"Respinge"}
                    </div>
                </div>
            </div>
        );
    }
    
    onClientUserAccept = (client) => {
        acceptNewUser(client['id'], (successful) => {
            if(successful) {
                this.props.toast("Noul utilizator a fost acceptat.");
            } else {
                this.props.toast("Operatiunea a esuat!", kToastActionError);
            }
            this.updatePendingUsers();
        });
        
    }
    
    onClientUserReject = (client) => {
        rejectNewUser(client['id'], (successful) => {
            if(successful) {
                this.props.toast("Noul utilizator a fost respins.");
            } else {
                this.props.toast("Operatiunea a esuat!", kToastActionError);
            }
            
            this.updatePendingUsers();
        });
    }
    
    onItemClick = (item) => {
        toClientPage(item.id);
    }
}

const ListItem = (props) => {
    return(
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>
            
            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
                
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.cif}
                        icon={'ft-info'}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.reg}
                        icon={'blank'}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.bank}
                        icon={'ft-credit-card'}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.iban}
                        icon={'blank'}
                        style={{marginBottom: 4}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.address}
                        icon={'ft-map-pin'}
                        style={{marginBottom: 4}}/>
                </div>
                
                
                <div className={'item-action-button'}>
                    <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                        {'Vezi clientul'}
                    </div>
                </div>
            
            
            </div>
        
        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ClientsPage));