import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllOrders} from '../functional/api';
import {withRouter} from 'react-router-dom';
import {kFilterOrderFields, kFilterOrderFieldsDefault} from '../functional/constants';
import ListPage from '../layout/page/ListPage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import AddOrderModal from '../components/modal/AddOrderModal';
import {toOrderPage, toSupplierPage} from '../functional/navigation';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

class OrdersPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = OrdersPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-orders-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(OrdersPage.defaultState, this.update);
    }
    
    update = () => {
        getAllOrders((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                grid
                deepSearch={false}
                loading={this.state.items == null}
                actions={[{
                    title: 'Adauga comanda',
                    icon: {title: 'ft-plus'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item, dark) => {
                    return (
                        <ListItem
                            key={item.id}
                            item={item}
                            dark={dark}
                            onPress={this.onItemClick}/>
                    );
                }}
                defaultFields={kFilterOrderFieldsDefault}
                allFields={kFilterOrderFields}>

                <AddOrderModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toOrderPage(item.id);
    }
    
}

const ListItem = (props) => {
    return(
        <div className={'grid-list-item'} style={{height: 'fit-content'}}>

            <div className={'inside-card'} style={{backgroundColor: props.dark ? '#fff' : '#fff'}}>
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item['name']}
                        style={{marginBottom: 4}}/>
    
                    <TextFieldDisplay
                        oneLine
                        value={props.item['status']}
                        icon={'ft-code'}
                        style={{marginBottom: 4}}/>
        
                    <TextFieldDisplay
                        oneLine
                        value={props.item.supplier.name}
                        icon={'ft-package'}
                        style={{marginBottom: 4}}
                        action={'ft-eye'}
                        onPress={() => {toSupplierPage(props.item.supplier.id)}}/>
                    
                    <TextFieldDisplay
                        oneLine
                        value={props.item.user['first_name'] + ' ' + props.item.user['last_name']}
                        icon={'ft-users'}
                        style={{marginBottom: 4}}/>
                </div>
                
                <div className={'item-action-button'}>
                    <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                        {'Vezi comanda'}
                    </div>
                </div>
                
            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(OrdersPage);