import React from 'react';
import PropTypes from 'prop-types';
import {smallIcon} from '../../functional/min-support';

class TimelineList extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        renderItem: PropTypes.func,
    }
    
    render() {
        let style = {};
        if(this.props.style) {
            style = {...style, ...this.props.style};
        }
        
        if(!this.props.items) {
            return null;
        }
        
        let classname = "w-100 d-flex flex-column";
        let recentClassname = "recent-activity"
        let recentStyle = {};
        if(this.props.horizontal) {
            recentStyle.display = 'flex';
            recentStyle.flexDirection = 'row';
            classname = "w-100 d-flex flex-row";
            recentClassname = "recent-activity-horizontal";
        }
        
        
        return (
            <div className={classname} style={{paddingTop: this.props.horizontal ? 0 : 20}}>
                <div className={recentClassname} style={recentStyle}>
                    {
                        this.props.items.map((item, key) => {
                            return (
                                <div className="timeline-task" key={item.key ? item.key : item.key}>
                                    <div className="icon" style={{backgroundColor: item.color ? item.color : '#999999', border: 'none'}}>
                                        {
                                            smallIcon(item.icon ? item.icon : 'ft-message-square', '#ffffff', 16)
                                        }
                                    </div>
                                    {
                                        this.props.renderItem && this.props.renderItem(item)
                                    }
                                    {
                                        !this.props.renderItem &&
                                        <React.Fragment>
                                            <div className="timeline_title">
                                                <h4>{item.title}</h4>
                                                <span className="time"><i className="ti-time text-primary"/>{item.time}</span>
                                            </div>
                                            <p>{item.description}</p>
                                        </React.Fragment>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default TimelineList;