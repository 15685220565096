import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class TicketCountChart extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#312f2f', '#6c1b1b'],
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        dataLabels: {
                            show: false
                        },
                        
                    },
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['Receptionat', 'Atribuit', 'In lucru', 'Finalizat'],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                }
            },
            series: [{
                name: 'Ieri',
                data: [1, 2, 1, 4]
            }, {
                name: 'Astazi',
                data: [2, 2, 2, 6]
            }],
        }
    }
    render() {
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="350" />
            </React.Fragment>
        );
    }
}

export default TicketCountChart;