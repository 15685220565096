import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openPDFViewModal, openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import {getTicket, kAPIEndpointPVI} from '../functional/api';
import {
    colorByStatus,
    kStatusAssigned,
    kStatusHasPVI,
    kStatusInProgress,
    kStatusItemsRequired,
    kStatusPartial,
    kStatusRepaired,
    kStatusScheduled,
    kStatusStopped
} from '../functional/status';
import moment from 'moment';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import TimelineList from '../components/lists/TimelineList';
import {filter, prettyDate, prettyDateAndTime, prettyDuration, prettyTimeFromServer} from '../functional/min-support';
import AddActionModal from '../components/modal/AddActionModal';
import {mincodeServicesGeneratePDF, openDeviz, openPVIServices, translateTicketActionList} from '../functional/logic';
import HorizontalSnapper from '../components/lists/HorizontalSnapper';
import {toClientPage} from '../functional/navigation';
import AddEstimativeModal from '../components/modal/AddEstimativeModal';
import AddPaperModal from '../components/modal/AddPaperModal';

class TicketPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        hasPVI: false,
        search: '',
        
        paperModal: false,
    };
    
    state = TicketPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(TicketPage.defaultState, this.update);
    }
    
    onPVIPressed = (id) => {
        //openPVIServices(id);
        this.props.openPDFViewModal('loading');
    
        mincodeServicesGeneratePDF(kAPIEndpointPVI + '?id=' + id, (successful, result) => {
            if(successful) {
                this.props.openPDFViewModal(result.versions[0].extended);
            }
        });
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getTicket(key, (successful, data) => {
            if(successful) {
                console.log('ticket', data);
                let hasPVI = false;
                let actions = data['actions'];
                let lastInProgress = null;
                for(let i = actions.length - 1; i >= 0; --i) {
                    if(kStatusHasPVI.includes(actions[i]['to_status'])) {
                        hasPVI = true;
                    }
                    if(actions[i]['from_status'] === kStatusInProgress) {
                        if(lastInProgress) {
                            actions[i]['real_difference'] = moment.duration(moment(actions[i]['created']).diff(moment(lastInProgress)));
                        }
                    }
                    if(actions[i]['to_status'] === kStatusInProgress) {
                        lastInProgress = actions[i]['created'];
                    }
                }
                this.setState({
                    item: data,
                    hasPVI: hasPVI,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let actions = this.state.item ? translateTicketActionList(this.state.item.actions) : null;
        let equipments = this.state.item ? this.state.item.equipments : null;
        let toolbarActions = [
            {
                title: 'Adauga imagine',
                onPress: () => {this.setState({addPictureModal: true});},
                icon: {title: 'ft-image'}
            },
            {
                title: 'Deviz estimativ',
                onPress: () => {this.setState({estimativeModal: true});},
                icon: {title: 'ft-file-minus'}
            },
            {
                title: 'Genereaza deviz',
                onPress: () => {
                    //this.props.openPDFViewModal('loading');
                    //openDeviz(this.state.item.id, this.props.user.username);
                    this.setState({paperModal: true});
                },
                icon: {title: 'ft-file-plus'}
            },
            {
                title: 'Adauga actiune',
                onPress: () => {this.setState({modalOpen: true});},
                icon: {title: 'ft-plus'}
            },
        ];
        let toolbarLabels = [
            {
                connection: 'se cauta in lista de actiuni dupa:',
                tags: ['Statusul tichetului', 'Numele utilizatorului', 'Prenumele utilizatorului']
            }
        ];
        if(actions && this.state.search.length > 0) {
            actions = filter(actions, ['status', 'search_first_name', 'search_last_name'], this.state.search, false);
        }
        
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{flex: 1, padding: 20}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{marginBottom: 4}}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'ticket',
                                                'name');
                                        }}/>
    
                                    <TextFieldDisplay
                                        textBackgroundColor={colorByStatus(this.state.item.status)}
                                        textColor={'#ffffff'}
                                        value={this.state.item.status}
                                        icon={'ft-code'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'creat la'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'tipul interventiei'}
                                        value={this.state.item.type}
                                        icon={'ft-clipboard'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'detalii generale'}
                                        value={this.state.item.description}
                                        icon={'ft-file-text'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.description,
                                                'ticket',
                                                'description');
                                        }}/>
                            
                                </div>
                                
                                <div className={'general-card'} style={{marginTop: 20, marginBottom: 20}}>
                                    <TextFieldDisplay
                                        label={'client'}
                                        value={this.state.item.client.name}
                                        icon={'ft-briefcase'}
                                        action={'ft-eye'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            toClientPage(this.state.item.client.id);
                                        }}/>
    
                                    {
                                        this.state.item.client.address &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.address}
                                            icon={'blank'}
                                            style={{marginBottom: 4}}/>
                                    }
    
                                    {
                                        this.state.item.client.cif &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.cif}
                                            icon={'blank'}
                                            style={{marginBottom: 4}}/>
                                    }
    
                                    {
                                        this.state.item.client.reg &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.reg}
                                            icon={'blank'}
                                            style={{marginBottom: 4}}/>
                                    }
    
                                    {
                                        this.state.item.client.bank &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.bank}
                                            icon={'blank'}
                                            style={{marginBottom: 4}}/>
                                    }
    
                                    {
                                        this.state.item.client.iban &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.iban}
                                            icon={'blank'}
                                            style={{marginBottom: 4}}/>
                                    }
    
                                    <TextFieldDisplay
                                        label={'punct de lucru'}
                                        value={this.state.item.client.headquarters.name}
                                        icon={'ft-map-pin'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        value={this.state.item.client.headquarters.address}
                                        icon={'blank'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'beneficiar'}
                                        value={this.state.item.beneficiary}
                                        icon={'ft-copy'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.beneficiary,
                                                'ticket',
                                                'beneficiary');
                                        }}/>
                                </div>
                                
                                {
                                    equipments && equipments.length > 0 &&
                                    <h3>Utilaje</h3>
                                }
    
                                {
                                    equipments && equipments.length > 0 &&
                                    <HorizontalSnapper items={equipments} renderItem={(eq) => {
                                        return (
                                            <div className="general-card" style={{width: 540}}>
                                                <TextFieldDisplay
                                                    value={eq.title}
                                                    style={{marginBottom: 4}}/>
                    
                                                <TextFieldDisplay
                                                    icon={'ft-cpu'}
                                                    value={eq.model}
                                                    style={{marginBottom: 4}}/>
                    
                                                <TextFieldDisplay
                                                    icon={'ft-hash'}
                                                    value={eq.series}
                                                    style={{marginBottom: 4}}/>
                    
                                                <TextFieldDisplay
                                                    icon={'ft-code'}
                                                    value={eq.status ? eq.status : 'Fara status'}
                                                    style={{marginBottom: 4}}/>
                    
                                                <span>Eticheta</span>
                                                <div style={{width: '100%', height: 200}}>
                                                    <ImageFieldDisplay value={eq['services_label_uid_m']} full={eq['services_label_uid_o']}/>
                                                </div>
                    
                                                <span>Garantie</span>
                                                <div style={{width: '100%', height: 200}}>
                                                    <ImageFieldDisplay value={eq['services_warranty_uid_m']} full={eq['services_warranty_uid_o']}/>
                                                </div>
    
                                                <TextFieldDisplay
                                                    value={'Vezi istoricul'}
                                                    icon={'ft-archive'}
                                                    action={'ft-eye'}
                                                    onPress={() => {toClientPage(this.state.item.client.id)}}
                                                    style={{marginTop: 4}}/>
                                            </div>
                                        );
                                    }}/>
                                }
    
                                {
                                    equipments && equipments.length <= 0 &&
                                    <h3>Niciun utilaj</h3>
                                }
    
                                {
                                    <h3 style={{marginTop: 20}}>Imagini adaugate</h3>
                                }
    
                                {
                                    this.state.item.images && this.state.item.images.length > 0 &&
                                    <HorizontalSnapper items={this.state.item.images} renderItem={(item) => {
                                        return (
                                            <div style={{height: 300, width: 500}}>
                                                <ImageFieldDisplay value={item['services_picture_uid_m']} full={item['services_picture_uid_o']}/>
                                            </div>
                                        );
                                    }}/>
                                }
    
                                {
                                    (!this.state.item.images || this.state.item.images.length <= 0) &&
                                    <div style={{height: 300, width: 500}}>
                                        <ImageFieldDisplay value={''}/>
                                    </div>
                                }

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                
                    <div className="h-100 overflow-scroll" style={{width: '30%', padding: 20, paddingLeft: 0}}>
                    
                        {
                            this.state.item && actions &&
                            <React.Fragment>
                                <TimelineList
                                    items={actions}
                                    renderItem={(item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{paddingLeft: 20}}>
                                                <TextFieldDisplay
                                                    textBackgroundColor={colorByStatus(item.status)}
                                                    textColor={'#ffffff'}
                                                    style={{marginBottom: 4}}
                                                    value={item.status}
                                                    icon={'ft-code'}/>
                                                
                                                <TextFieldDisplay
                                                    style={{marginBottom: 4}}
                                                    value={prettyDateAndTime(item.created)}
                                                    icon={'ft-clock'}/>
    
                                                <TextFieldDisplay
                                                    value={item.user['first_name'] + ' ' + item.user['last_name']}
                                                    icon={'ft-users'}/>
                                                    
                                                {
                                                    ticketActionAdvancedFields(item, this.onPVIPressed)
                                                }
                                            </div>
                                        );
                                    }}/>
                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                
                    </div>
                </div>
                
                {
                    this.state.item &&
                    <AddEstimativeModal
                        open={this.state.estimativeModal}
                        parentKey={this.state.item.id}
                        onClose={() => {
                            this.setState({
                                estimativeModal: false,
                            });
                            //this.props.openPDFViewModal('loading');
                            this.update();
                        }}/>
                }
    
                {
                    this.state.item &&
                    <AddPaperModal
                        open={this.state.paperModal}
                        parentKey={this.state.item.id}
                        onClose={() => {
                            this.setState({
                                paperModal: false,
                            });
                            this.update();
                        }}/>
                }
            
                {
                    this.state.item &&

                    <AddActionModal
                        fromStatus={this.state.item.status}
                        parentKey={this.state.item.id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>
                
                }
        
            </React.Fragment>
        );
    }
}

export const ticketActionAdvancedFields = (action, onPVIPressed) => {
    switch(action.status) {
        case kStatusPartial:
            return (
                <React.Fragment>
                    {
                        action['real_difference'] &&
                        <TextFieldDisplay
                            icon={'ft-watch'}
                            style={{marginBottom: 4}}
                            label={'a stat in lucru'}
                            value={prettyDuration(action['real_difference'])}/>
                    }
                    
                    {
                        action['secret'] &&
                        <TextFieldDisplay
                            icon={'ft-eye-off'}
                            style={{marginBottom: 4}}
                            label={'informatii private de la tehnician'}
                            value={action['secret']}/>
                    }
                    
                    <TextFieldDisplay
                        icon={'ft-file'}
                        value={'Vezi proces verbal'}
                        action={'ft-eye'}
                        onPress={() => {onPVIPressed(action.id)}}/>
                </React.Fragment>
            );
        
        case kStatusItemsRequired:
            return (
                <React.Fragment>
                    {
                        action['real_difference'] &&
                        <TextFieldDisplay
                            icon={'ft-watch'}
                            style={{marginBottom: 4}}
                            label={'a stat in lucru'}
                            value={prettyDuration(action['real_difference'])}/>
                    }
                    
                    {
                        action['secret'] &&
                        <TextFieldDisplay
                            icon={'ft-eye-off'}
                            style={{marginBottom: 4}}
                            label={'informatii private de la tehnician'}
                            value={action['secret']}/>
                    }
                    
                    <TextFieldDisplay
                        icon={'ft-file'}
                        value={'Vezi proces verbal'}
                        action={'ft-eye'}
                        onPress={() => {onPVIPressed(action.id)}}/>
                </React.Fragment>
            );
        
        case kStatusRepaired:
            return (
                <React.Fragment>
                    {
                        action['real_difference'] &&
                        <TextFieldDisplay
                            icon={'ft-watch'}
                            style={{marginBottom: 4}}
                            label={'a stat in lucru'}
                            value={prettyDuration(action['real_difference'])}/>
                    }
                    
                    {
                        action['secret'] &&
                        <TextFieldDisplay
                            icon={'ft-eye-off'}
                            style={{marginBottom: 4}}
                            label={'informatii private de la tehnician'}
                            value={action['secret']}/>
                    }
                    
                    <TextFieldDisplay
                        icon={'ft-file'}
                        value={'Vezi proces verbal'}
                        action={'ft-eye'}
                        onPress={() => {onPVIPressed(action.id)}}/>
                </React.Fragment>
            );
        
        case kStatusStopped:
            return (
                <React.Fragment>
                    {
                        action['reason'] &&
                        <TextFieldDisplay
                            icon={'ft-alert-triangle'}
                            style={{marginBottom: 4}}
                            label={'motivul opririi'}
                            value={action['reason']}/>
                    }
                    {
                        action['real_difference'] &&
                        <TextFieldDisplay
                            icon={'ft-watch'}
                            style={{marginBottom: 4}}
                            label={'a stat in lucru'}
                            value={prettyDuration(action['real_difference'])}/>
                    }
                    
                    {
                        action['secret'] &&
                        <TextFieldDisplay
                            icon={'ft-eye-off'}
                            style={{marginBottom: 4}}
                            label={'informatii private de la tehnician'}
                            value={action['secret']}/>
                    }
                    
                    <TextFieldDisplay
                        icon={'ft-file'}
                        value={'Vezi proces verbal'}
                        action={'ft-eye'}
                        onPress={() => {onPVIPressed(action.id)}}/>
                </React.Fragment>
            );
        
        case kStatusAssigned:
            return (
                <React.Fragment>
                    <TextFieldDisplay
                        icon={'ft-arrow-right-circle'}
                        label={'atribuit operatorului'}
                        value={action['assigned']['first_name'] + ' ' + action['assigned']['last_name']}/>
                </React.Fragment>
            );
        
        case kStatusScheduled:
            return (
                <React.Fragment>
                    <TextFieldDisplay
                        icon={'ft-calendar'}
                        label={'programat pentru'}
                        value={prettyDate(action['schedule']) + ' ora ' + prettyTimeFromServer(action['schedule_time'])}/>
                </React.Fragment>
            );
    }
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
        
        openPDFViewModal: (link) => dispatch(openPDFViewModal(link)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketPage));