import {createSlice} from '@reduxjs/toolkit';
import {translateUser} from "../functional/logic";

/**
 * Manages application related information.
 */

const initialState = {
    isAuthenticated: false,
    user: {
        firstName: '',
        lastName: '',
        username: '',
        uid: '',
        level: 0,
        phone: '',
        email: '',
        hasCustomLogo: '',
        isPerson: false,
    },

    drawerOpen: true,

    itemPageTitle: 'Se incarca...',
    snack: [],
    snackMonitor: 0,

    modals: {
        updateModal: false,
        loadingModal: false,
        addEmailModal: false,

        pdfViewModal: false,
    },

    pdfViewModalInfo: {
        url: '',
        data: null,
    },

    updateModalInfo: {
        id: '',
        previousValue: '',
        type: '',
        on: '',
    },
};

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        applicationSetIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        applicationSetUser: (state, action) => {
            state.user = translateUser(action.payload);
        },
        applicationToggleDrawerOpen: (state) => {
            state.drawerOpen = !state.drawerOpen;
        },
        applicationSetItemPageTitle: (state, action) => {
            state.itemPageTitle = action.payload ? action.payload : 'Se incarca...';
        },

        applicationAddSnack: (state, action) => {
            let snack = [...state.snack];
            snack.push({label: action.payload.label, action: action.payload.action});
            state.snack = snack;
        },
        applicationRemoveSnack: (state, action) => {
            let snack = state.snack;
            snack.shift();
            state.snack = snack;
        },

        applicationOpenUpdateModal: (state, action) => {
            let modals = state.modals;
            let info = action.payload;
            modals.updateModal = true;
            let updateModal = {
                id: info.id,
                previousValue: info.previousValue,
                type: info.updateType,
                on: info.on,
            };

            state.modals = modals;
            state.updateModalInfo = updateModal;
        },
        applicationCloseUpdateModal: (state) => {
            let modals = state.modals;
            modals.updateModal = false;
            let updateModal = {
                id: 0,
                previousValue: '',
                type: '',
                on: '',
            };

            state.modals = modals;
            state.updateModalInfo = updateModal;
        },

        applicationOpenPDFViewModal: (state, action) => {
            let modals = state.modals;
            let info = action.payload;
            modals.pdfViewModal = true;

            state.modals = modals;
            state.pdfViewModalInfo = {url: info.link, data: info.data};
        },
        applicationClosePDFViewModal: (state) => {
            let modals = state.modals;
            modals.pdfViewModal = false;

            state.modals = modals;
            state.pdfViewModalInfo = {url: '', data: null};
        },

        applicationOpenLoadingModal: (state) => {
            let modals = state.modals;
            modals.loadingModal = true;
            state.modals = modals;
        },
        applicationCloseLoadingModal: (state) => {
            let modals = state.modals;
            modals.loadingModal = false;
            state.modals = modals;
        },

        applicationOpenAddEmailModal: (state) => {
            let modals = state.modals;
            modals.addEmailModal = true;
            state.modals = modals;
        },
        applicationCloseAddEmailModal: (state) => {
            let modals = state.modals;
            modals.addEmailModal = false;
            state.modals = modals;
        },
    }
});

export const {
    applicationSetIsAuthenticated,
    applicationSetUser,

    applicationToggleDrawerOpen,

    applicationSetItemPageTitle,

    applicationAddSnack,
    applicationRemoveSnack,

    applicationOpenUpdateModal,
    applicationCloseUpdateModal,

    applicationOpenPDFViewModal,
    applicationClosePDFViewModal,

    applicationOpenLoadingModal,
    applicationCloseLoadingModal,

    applicationOpenAddEmailModal,
    applicationCloseAddEmailModal,
} = applicationSlice.actions;

export const applicationIsAuthenticatedSelector = (state) => state.application.isAuthenticated;
export const applicationUserSelector = (state) => state.application.user;

export const applicationDrawerOpenSelector = (state) => state.application.drawerOpen;
export const applicationItemPageTitleSelector = (state) => state.application.itemPageTitle;

export const applicationSnackSelector = (state) => state.application.snack;
export const applicationSnackMonitorSelector = (state) => state.application.snackMonitor;

export const applicationUpdateModalOpenSelector = (state) => state.application.modals.updateModal;
export const applicationUpdateModalInfoSelector = (state) => state.application.updateModalInfo;

export const applicationPDFViewModalOpenSelector = (state) => state.application.modals.pdfViewModal;
export const applicationPDFViewModalInfoSelector = (state) => state.application.pdfViewModalInfo;

export const applicationLoadingModalOpenSelector = (state) => state.application.modals.loadingModal;
export const applicationAddEmailModalOpenSelector = (state) => state.application.modals.loadingModal;

export default applicationSlice.reducer;
