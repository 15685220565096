import React from 'react';
import TextFieldDisplay from '../input/TextFieldDisplay';
import {prettyDateAndTime} from '../../functional/min-support';
import HorizontalSnapper from '../lists/HorizontalSnapper';
import ImageFieldDisplay from '../input/ImageFieldDisplay';
import {openPDFViewModal} from '../../store/actions/application-store-actions';
import {connect} from 'react-redux';

class ListItem extends React.Component {
    
    render() {
        let props = this.props;
        let addStyle = {alignItems: 'stretch'};
        let iconColor = '#6c1b1b';
        /*if(props.item.status == 'unread') {
            iconColor = '#6c1b1b';
        } else if (props.item.priority == 'normal') {
            iconColor = '#936a0f';
        } else if (props.item.priority == 'high' || props.item.priority == 'urgent') {
            iconColor = '#6c1b1b';
        }*/
        
        //let actions = translateTicketActionList(props.item.actions);
        
        let senderAddress = '';
        if(props.item.fromName) {
            senderAddress += props.item.fromName + ' <';
        }
        
        if(props.item.fromAddress) {
            senderAddress += props.item.fromAddress;
        }
    
        if(props.item.fromName) {
            senderAddress += '>';
        }
        
        return(
            <div className={'sectioned-list-item-compact'}>
                <div className="sectioned-list-item-card" style={addStyle}>
                    
                    <div className={'list-item-section'}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                            <TextFieldDisplay
                                oneLine
                                value={props.item.subject}
                                style={{flex: 1}}/>
                            
                            {
                                <div className={'prio-status'} style={{backgroundColor: iconColor}}>
                                    {'Necitit'}
                                </div>
                            }
                        
                        </div>
                        
                        <TextFieldDisplay
                            oneLine
                            value={prettyDateAndTime(props.item.created)}
                            icon={'ft-calendar'}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            value={'Niciun tichet asociat'}
                            icon={'ft-check-square'}
                            style={{marginBottom: 4}}/>
                    </div>
                    
                    <div className={'list-item-section'} style={{borderLeftWidth: 1}}>
                        <TextFieldDisplay
                            oneLine
                            value={senderAddress}
                            icon={'ft-at-sign'}
                            style={{marginBottom: 4}}/>
                        
                        <TextFieldDisplay
                            oneLine
                            label={'client'}
                            value={props.item.fromName}
                            icon={'ft-briefcase'}
                            action={'ft-eye'}
                            onPress={() => {}}
                            style={{marginBottom: 4}}/>
                    </div>
                    
                    <div className={'list-item-section-with-button'}>
                        
                        <div className={'inside-list-item-section'}>
                            
                            <TextFieldDisplay
                                oneLine
                                value={props.item.text}
                                icon={'ft-align-left'}
                                style={{marginBottom: 4}}/>
                                
                            {
                                props.item.images && props.item.images.length > 0 &&
                                <HorizontalSnapper small items={props.item.images} renderItem={(item) => {
                                    return (
                                        <div style={{height: 140, width: 240}}>
                                            <ImageFieldDisplay value={item['services_picture_uid_m']} full={item['services_picture_uid_o']}/>
                                        </div>
                                    );
                                }}/>
                            }
                        </div>
                        
                        <div className={'item-action-button'}>
                            <div onClick={() => {props.onPress(props.item);}} className={'item-action-button-inside'}>
                                {'Vezi email-ul'}
                            </div>
                        </div>
                    
                    </div>
                
                </div>
            </div>
        );
    }
    
};

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link) => dispatch(openPDFViewModal(link)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);