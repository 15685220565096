import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import {colorByStatus} from '../../../functional/status';

class TicketCountChart extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            options: {
            
            },
            series: [{
                name: 'Acum',
                data: [2, 2, 2, 6]
            }],
        }
    }
    
    static defaultOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        colors: ['#312f2f', '#6c1b1b'],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                dataLabels: {
                    show: false
                },
            },
        },
        legend: {
            show: true
        },
        dataLabels: {
            enabled: true,
        },
        grid: {
            borderColor: 'transparent',
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['#fff']
        },
        xaxis: {
            categories: ['Tichete'],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        }
    };
    
    render() {
        let data = this.props.data;
        let option = TicketCountChart.defaultOptions;
        let series = [];
        
        if(data) {
            option.colors = [];
            for (const [key, value] of Object.entries(data)) {
                series.push({
                    name: key,
                    data: [value],
                });
                option.colors.push(colorByStatus(key));
            }
        }
        
        return (
            <React.Fragment>
                <ReactApexChart options={option} series={series} type="bar" height="350" />
            </React.Fragment>
        );
    }
}

export default TicketCountChart;