import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {addInvoice, getAllPapersForInvoice, getAllSalesForClient} from '../../functional/api';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationIntegerMoreThan,
    inputValidationSingleChoice,
    prettyDateAndTime
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        clientId: -1,
        
        deadline: "30",
        
        sales: [],
        salesSelected: [],
        
        papers: [],
        papersSelected: [],
        
        name: '',

        formComplete: false,
    };

    render () {

        return (
            <BaseModal
                title={'Adauga factura'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>
    
                        <SingleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationSingleChoice(this.state.clientId)}
        
                            icon={'ft-briefcase'}
                            label={'Clientul'}
                            predefinedModal={'client'}
                            value={this.state.clientId}
                            style={{marginBottom: 4}}
                            onSelect={(value)=>{this.setState({
                                clientId: value,
                                salesSelected: [],
                                papersSelected: [],
                            }, () => {
                                this.updateForClientId();
                            });}}/>
    
                        <TextFieldInput
                            required
                            requireCompletion
                            validated={inputValidationIntegerMoreThan(this.state.deadline, 0)}
        
                            value={this.state.deadline}
                            style={{marginBottom: 4}}
                            icon={'ft-calendar'}
                            label={'Numarul de zile pana la scadenta'}
                            onChange={(newValue) => {this.setState({
                                deadline: newValue,
                            });}}/>
    
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={true}
                            search
        
                            minimumChoices={0}
                            maximumChoices={100}
        
                            icon={'ft-credit-card'}
                            label={'Alege avizele adaugate la factura'}
                            options={this.state.sales}
                            values={this.state.salesSelected}
                            style={{marginBottom: 4}}
                            onSelect={(values)=>{this.setState({
                                salesSelected: values,
                            });}}/>
    
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={true}
                            search
        
                            minimumChoices={0}
                            maximumChoices={100}
        
                            icon={'ft-file-text'}
                            label={'Alege devizele adaugate la factura'}
                            options={this.state.papers}
                            values={this.state.papersSelected}
                            style={{marginBottom: 4}}
                            onSelect={(values)=>{this.setState({
                                papersSelected: values,
                            });}}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let request = {
            name: this.state.name,
            clientId: this.state.clientId,
            sales: this.state.salesSelected,
            papers: this.state.papersSelected,
            deadline: this.state.deadline,
        };

        this.props.openLoading();

        addInvoice(request, (successful) => {
            if(successful) {
                this.clear();
                this.props.toast('Factura inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
    
    }
    
    updateForClientId = () => {
        getAllSalesForClient(this.state.clientId, (successful, data) => {
            if(successful) {
                this.setState({
                    sales: data.map(item => {return {
                        value: item.id,
                        label: 'Aviz ' + item.name,
                        description: 'Creat de ' + item['first_name'] + ' ' + item['last_name'] + ' la data ' + prettyDateAndTime(item.created),
                    };}),
                });
            } else {
                console.error('no add action info');
            }
        });
    
        getAllPapersForInvoice(this.state.clientId, (successful, data) => {
            if(successful) {
                this.setState({
                    papers: data.map(item => {return {
                        value: item.id,
                        label:  'Deviz la tichetul ' + item.ticket.name,
                        description: 'Creat de ' + item.poster['first_name'] + ' ' + item.poster['last_name'] + ' la data ' + prettyDateAndTime(item.created),
                    };}),
                });
            } else {
                console.error('no add action info');
            }
        });
    };

    clear = () => {
        this.setState({
            clientId: -1,
            stock: [],
            stockSelected: [],
            stockQuantity: {},
            stockPrice: {},
            name: '',
        });
    }

}

const StockEntry = (props) => {
    return (
        <div
            style={{width: '100%', padding: 10, marginBottom: 10, backgroundColor: '#eeeeee', display: 'flex', flexDirection: 'row', alignItems:'flex-end', flex: 5}}>
            
            <TextFieldDisplay
                style={{flex: 4}}
                label={'piesa'}
                value={props.item.label}
                icon={'intervention'}/>
    
            <TextFieldInput
                flex={1}
                style={{flex: 1}}
                label={'Numar bucati'}
                value={props.quantity}
                onChange={(newValue) => {props.onChange(props.item.value, newValue, props.price)}}/>
    
            <TextFieldInput
                flex={1}
                style={{flex: 1}}
                label={'Pret per bucata (RON)'}
                value={props.price}
                onChange={(newValue) => {props.onChange(props.item.value, props.quantity, newValue)}}/>
        
        </div>
    );
};

StockEntry.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(null, mapDispatchToProps)(Modal);