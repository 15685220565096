import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';



import {addStock, getAddStockInfo, uploadStockPhoto} from '../../functional/api';
import {closeLoadingModal, openLoadingModal, snackAdd} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {inputValidationFloat, inputValidationNonEmpty, inputValidationSingleChoice} from '../../functional/min-support';
import {kQuantityTypes} from '../../functional/constants';
import TextFieldInput from '../input/TextFieldInput';
import {kToastActionError} from '../general/Toast';
import Form from '../input/Form';
import ImageFieldInput from '../input/ImageFieldInput';

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        supplierId: -1,
        suppliers: [],

        name: '',
        model: '',
        series: '',
        quantityType: 'bucati',

        formComplete: false,
        
        image: null,
        imageURL: null,
        
        price: '',
    };

    render () {

        return (
            <BaseModal
                title={'Adauga piesa'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {this.setState({formComplete: complete});}}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.name)}

                            value={this.state.name}
                            style={{marginBottom: 4}}
                            label={'Numele piesei'}
                            onChange={(newValue) => {this.setState({
                                name: newValue,
                            });}}/>
    
                        <ImageFieldInput
                            local
                            style={{marginBottom: 4}}
                            value={this.state.imageURL}
                            onChange={this.onPictureChange} />

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.supplierId)}

                            search
                            icon={'client'}
                            label={'Alege furnizorul'}
                            options={this.state.suppliers}
                            value={this.state.supplierId}
                            style={{marginBottom: 4}}
                            onSelect={(value)=>{this.setState({
                                supplierId: value,
                            });}}/>

                        <TextFieldInput
                            requireCompletion

                            icon={'intervention'}
                            value={this.state.model}
                            style={{marginBottom: 4}}
                            label={'Codul piesei'}
                            onChange={(newValue) => {this.setState({
                                model: newValue,
                            });}}/>
    
                        {
                            (true || (this.props.user && this.props.user.level == -2)) &&
                            <TextFieldInput
                                requireCompletion
                                icon={'code'}
                                value={this.state.placement}
                                style={{marginBottom: 4}}
                                label={'Pozitia piesei'}
                                onChange={(newValue) => {this.setState({
                                    placement: newValue,
                                });}}/>
                        }
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.quantityType)}
        
                            icon={'count'}
                            label={'Alege tipul cantitatii'}
                            options={kQuantityTypes}
                            value={this.state.quantityType}
                            style={{marginBottom: 4}}
                            onSelect={(value)=>{this.setState({
                                quantityType: value,
                            });}}/>
    
                        <TextFieldInput
                            required
                            requireCompletion
                            validated={inputValidationFloat(this.state.price)}
                            
                            icon={'price'}
                            value={this.state.price}
                            style={{marginBottom: 4}}
                            label={'Pretul de baza al piesei'}
                            onChange={(newValue) => {this.setState({
                                price: newValue,
                            });}}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }
    
    onPictureChange = (image, imageURL) => {
        this.setState({
            image: image,
            imageURL: imageURL,
        });
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let request = {
            name: this.state.name,
            supplierId: this.state.supplierId,
            model: this.state.model,
            series: this.state.series,
            placement: this.state.placement,
            price: this.state.price ? this.state.price : 0,
        };

        this.props.openLoading();

        addStock(request, (successful, data) => {
            if(successful) {
                this.addPicture(data);
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
                this.props.closeLoading();
                this.onClose();
            }
        });
    };
    
    addPicture = (stockId) => {
        if(this.state.image) {
            uploadStockPhoto(this.state.image, stockId, (successful) => {
                if (successful) {
                    this.clear();
                    this.props.toast('Piesa inregistrata cu succes.');
                } else {
                    this.props.toast('Adaugarea pozei esuata!', kToastActionError);
                }
                this.props.closeLoading();
                this.onClose();
            });
        } else {
            this.props.toast('Piesa inregistrata cu succes.');
            this.props.closeLoading();
            this.onClose();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
        getAddStockInfo((successful, data) => {
            if(successful) {
                let suppliers = data.suppliers.map((supplier) => {
                    return {
                        label: supplier.name,
                        value: parseInt(supplier.id)
                    };
                });
                this.setState({
                    suppliers: suppliers,
                });
            } else {
                console.error('no add action info');
            }
        });
    };

    clear = () => {
        this.setState({
            supplierId: -1,
            suppliers: [],

            name: '',
            model: '',
            series: '',
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => dispatch(snackAdd(label, action)),

        openLoading: () => dispatch(openLoadingModal()),
        closeLoading: () => dispatch(closeLoadingModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);