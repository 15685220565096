import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import {getClient, getSale} from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {filter, prettyDateAndTime} from '../functional/min-support';
import {toClientPage, toStockItemPage, toTicketPage} from '../functional/navigation';
import {
    openSale,
    translateClientHeadquarters,
    translateClientTickets,
    translateSaleheadquarters
} from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import AddHeadquartersModal from '../components/modal/AddHeadquartersModal';
import {colorByStatus} from '../functional/status';
import HorizontalSnapper from '../components/lists/HorizontalSnapper';

class ClientPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };
    
    state = ClientPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(ClientPage.defaultState, this.update);
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getClient(key, (successful, data) => {
            if(successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let headquarters = this.state.item ? translateClientHeadquarters(this.state.item.headquarters) : null;
        let tickets = this.state.item ? translateClientTickets(this.state.item.tickets) : null;
        let toolbarActions = [
            {
                title: 'Adauga punct de lucru',
                onPress: () => {this.setState({modalOpen: true});},
                icon: {title: 'ft-plus'}
            },
        ];
        let toolbarLabels = [
            {
                connection: 'se cauta puncte de lucru dupa:',
                tags: ['Denumirea locatiei', 'Adresa locatiei']
            },
            {
                connection: 'se cauta tichete dupa:',
                tags: ['Numarul tichetului', 'Statusul tichetului']
            }
        ];
        if(headquarters && this.state.search.length > 0) {
            headquarters = filter(headquarters, ['name', 'address'], this.state.search, false);
        }
    
        if(tickets && this.state.search.length > 0) {
            tickets = filter(tickets, ['name', 'status'], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{flex: 1, padding: 20}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
    
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{marginBottom: 4}}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'client',
                                                'name');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'cod identificare fiscala'}
                                        value={this.state.item.cif}
                                        icon={'ft-info'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.cif,
                                                'client',
                                                'cif');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'nr. inregistrare la registrul comertului'}
                                        value={this.state.item.reg}
                                        icon={'blank'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.reg,
                                                'client',
                                                'reg');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'banca'}
                                        value={this.state.item.bank}
                                        icon={'ft-credit-card'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.bank,
                                                'client',
                                                'bank');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'IBAN cont bancar'}
                                        value={this.state.item.iban}
                                        icon={'blank'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.iban,
                                                'client',
                                                'iban');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'adresa sediului social'}
                                        value={this.state.item.address}
                                        icon={'ft-map-pin'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.address,
                                                'client',
                                                'address');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'email contact'}
                                        value={this.state.item.email}
                                        icon={'ft-mail'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.email,
                                                'client',
                                                'email');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'nr. telefon contact'}
                                        value={this.state.item.email}
                                        icon={'ft-phone'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.phone,
                                                'client',
                                                'phone');
                                        }}/>

                                    <TextFieldDisplay
                                        label={'cost manopera'}
                                        value={this.state.item['work_price']}
                                        icon={'ft-dollar-sign'}
                                        action={'ft-edit-2'}
                                        style={{marginBottom: 4}}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item['work_price'],
                                                'client',
                                                'work_price');
                                        }}/>
                            
                                </div>
                                
                                {
                                    headquarters && headquarters.length > 0 &&
                                        <h3 style={{marginTop: 20}}>
                                            Puncte de lucru
                                        </h3>
                                }
    
                                {
                                    headquarters && headquarters.length > 0 &&
                                    headquarters.map((item) => {
                                        return (
                                            <div className="d-flex flex-column" style={{marginBottom: 20}}>
                                                <div className={'general-card'} style={{width: '100%'}}>
                                                    <TextFieldDisplay
                                                        icon={'ft-compass'}
                                                        value={item.name}
                                                        style={{marginBottom: 4}}/>
                                                        
                                                    <TextFieldDisplay
                                                        icon={'ft-map-pin'}
                                                        value={item.address}
                                                        style={{marginBottom: 4}}/>
    
                                                    <TextFieldDisplay
                                                        icon={'ft-clock'}
                                                        value={prettyDateAndTime(item.created)}/>
                                                        
                                                    {
                                                        item.email &&
                                                        <TextFieldDisplay
                                                            icon={'ft-mail'}
                                                            value={item.email}
                                                            style={{marginTop: 4}}/>
                                                    }
                                                </div>
    
                                                {
                                                    item.equipments && item.equipments.length > 0 &&
                                                    <HorizontalSnapper items={item.equipments} renderItem={(eq) => {
                                                        return (
                                                            <div className="general-card" style={{width: 540, marginTop: 20}}>
                                                                <TextFieldDisplay
                                                                    value={eq.title}
                                                                    style={{marginBottom: 4}}/>
                                                                    
                                                                <TextFieldDisplay
                                                                    icon={'ft-cpu'}
                                                                    value={eq.model}
                                                                    style={{marginBottom: 4}}/>
                    
                                                                <TextFieldDisplay
                                                                    icon={'ft-hash'}
                                                                    value={eq.series}
                                                                    style={{marginBottom: 4}}/>
    
                                                                <TextFieldDisplay
                                                                    icon={'ft-code'}
                                                                    value={eq.status ? eq.status : 'Fara status'}
                                                                    style={{marginBottom: 4}}/>
                                                                    
                                                                <span>Eticheta</span>
                                                                <div style={{width: '100%', height: 200}}>
                                                                    <ImageFieldDisplay value={eq['services_label_uid_m']} full={eq['services_label_uid_o']}/>
                                                                </div>
    
                                                                <span>Garantie</span>
                                                                <div style={{width: '100%', height: 200}}>
                                                                    <ImageFieldDisplay value={eq['services_warranty_uid_m']} full={eq['services_warranty_uid_o']}/>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}/>
                                                }
                                                
                                            </div>
                                        )
                                    })
                                }
    
                                {
                                    headquarters && headquarters.length <= 0 &&
                                    <h3 style={{marginTop: 20}}>
                                        Niciun punct de lucru
                                    </h3>
                                }

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                
                    <div className="h-100 overflow-scroll" style={{width: '40%', padding: 20, paddingLeft: 0}}>
                    
                        {
                            this.state.item && tickets &&
                            <React.Fragment>
                                {
                                    tickets.length > 0 && tickets.map((item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{marginBottom: 20}}>
    
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{flex:1, paddingRight: 20}}>
                                                        <TextFieldDisplay
                                                            style={{marginBottom: 4}}
                                                            value={item.name}
                                                            icon={'ft-check-square'}/>
    
                                                        <TextFieldDisplay
                                                            textBackgroundColor={colorByStatus(item.status)}
                                                            textColor={'#ffffff'}
                                                            value={item.status}
                                                            icon={'ft-code'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={prettyDateAndTime(item.created)}
                                                            icon={'ft-clock'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={item.type}
                                                            icon={'ft-clipboard'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={item.description}
                                                            icon={'ft-file-text'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            action={'ft-eye'}
                                                            onPress={()=>{toTicketPage(item['id'])}}
                                                            value={'Vezi ticket'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                
                                {
                                    tickets && tickets.length <= 0 &&
                                    <h3>
                                        Niciun tichet
                                    </h3>
                                }
                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                
                    </div>
                </div>
                
                {
                    this.state.item &&
                    <AddHeadquartersModal
                        parentKey={this.state.item.id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>
                }
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientPage));