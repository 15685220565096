export const kStatusReceived = 'Receptionat';
export const kStatusInProgress = 'In lucru';
export const kStatusRepaired = 'Reparat';
export const kStatusFinished = 'Finalizat';
export const kStatusCanceled = 'Anulat';
export const kStatusStopped = 'Oprit';
export const kStatusPartial = 'Lucrare partiala';

export const kStatusRescheduled = 'Reprogramat';
export const kStatusItemsRequired = 'Necesita piese';
export const kStatusScheduled = 'Programat';
export const kStatusAssigned = 'Atribuit';

export const kStatusAcknoledged = 'Constatat';
export const kStatusAwaitsItems = 'Asteapta piese';
export const kStatusItemsReceived = 'Piese sosite';
export const kStatusLeftWithUs = 'Ramas la noi';
export const kStatusCameBack = 'Revenit';
export const kStatusWarehouse = 'Depozit';
export const kStatusField = 'Teren';
export const kStatusDelivered = 'Predat';

export const kStatusNotInvoiced = 'Nefacturat';
export const kStatusTheRest = 'Restul';

export const kStatusAwaitsApproval = 'Asteapta aprobare';

export const kStatusAll = 'all';

export const kStatusDescription = {};
kStatusDescription[kStatusAwaitsApproval] = 'Tichete primite direct de la client, inca neaprobate.'
kStatusDescription[kStatusReceived] = 'Tichete primite de la administrator, inca neatribuite.'
kStatusDescription[kStatusAssigned] = 'Tichete atribuite deja unui operator.'
kStatusDescription[kStatusScheduled] = 'Tichete programate pentru o anumita data si ora.'
kStatusDescription[kStatusInProgress] = 'Tichete pentru care se desfasoara acum o interventie.'
kStatusDescription[kStatusStopped] = 'Tichete pentru care interventia curenta a fost oprita.'
kStatusDescription[kStatusItemsRequired] = 'Tichete pentru care este nevoie de piese.'
kStatusDescription[kStatusAwaitsItems] = 'Tichete pentru care se asteapta piese.'
kStatusDescription[kStatusRepaired] = 'Tichete rezolvate, care nu au inca un deviz creat.'
kStatusDescription[kStatusNotInvoiced] = 'Tichete rezolvate, care nu au inca un o factura creata.'
kStatusDescription[kStatusFinished] = 'Tichete finalizate, cu factura creata.'
kStatusDescription[kStatusPartial] = 'Tichete inca nefinalizate, dar cu lucrari deja efectuate.'

export const kStatusHasPVI = [];
kStatusHasPVI.push(kStatusPartial);
kStatusHasPVI.push(kStatusItemsRequired);
kStatusHasPVI.push(kStatusRepaired);

export const kStatusTransition = {};

kStatusTransition['all'] = [
    kStatusAssigned,
    kStatusScheduled,
    kStatusCanceled,
];

kStatusTransition[kStatusAwaitsApproval] = [
    kStatusReceived,
    kStatusCanceled,
];

kStatusTransition[kStatusReceived] = [
    ...kStatusTransition['all'],
];

kStatusTransition[kStatusAssigned] = [
    ...kStatusTransition['all'],
];

kStatusTransition[kStatusItemsRequired] = [
    kStatusItemsReceived,
    ...kStatusTransition['all'],
];

const kStatusColor = {};

kStatusColor[kStatusAwaitsApproval] = '#c60404';
kStatusColor[kStatusReceived] = '#8d0101';
kStatusColor[kStatusAssigned] = '#520101';
kStatusColor[kStatusScheduled] = '#380000';

kStatusColor[kStatusItemsRequired] = '#f5a504';
kStatusColor[kStatusPartial] = '#c48405';
kStatusColor[kStatusInProgress] = '#905c01';
kStatusColor[kStatusStopped] = '#613b02';

kStatusColor[kStatusRepaired] = '#03a52a';
kStatusColor[kStatusNotInvoiced] = '#025c18';
kStatusColor[kStatusFinished] = '#233b27';
kStatusColor[kStatusAll] = '#202421';

kStatusColor[kStatusRescheduled] = '#621475';
kStatusColor[kStatusAcknoledged] = '#35373a';
kStatusColor[kStatusAwaitsItems] = '#906119';
kStatusColor[kStatusItemsReceived] = '#161653';
kStatusColor[kStatusLeftWithUs] = '#3a2606';
kStatusColor[kStatusCameBack] = '#35373a';
kStatusColor[kStatusWarehouse] = '#10393a';
kStatusColor[kStatusField] = '#35373a';
kStatusColor[kStatusDelivered] = '#35373a';

kStatusColor[kStatusCanceled] = '#545459';


const kStatusIcon = {};

kStatusIcon[kStatusAwaitsApproval] = 'ft-watch';
kStatusIcon[kStatusReceived] = 'ft-phone-incoming';
kStatusIcon[kStatusAssigned] = 'ft-award';
kStatusIcon[kStatusScheduled] = 'ft-clock';

kStatusIcon[kStatusItemsRequired] = 'ft-layers';
kStatusIcon[kStatusPartial] = 'ft-pie-chart';
kStatusIcon[kStatusInProgress] = 'ft-play';
kStatusIcon[kStatusStopped] = 'ft-pause';

kStatusIcon[kStatusRepaired] = 'ft-check-circle';
kStatusIcon[kStatusNotInvoiced] = 'ft-file-minus';
kStatusIcon[kStatusFinished] = 'ft-thumbs-up';
kStatusIcon[kStatusAll] = 'ft-copy';

export const colorByStatus = (status) => {
    if(kStatusColor.hasOwnProperty(status)) {
        return kStatusColor[status];
    } else {
        return '#35373a';
    }
};

export const iconByStatus = (status) => {
    if(kStatusIcon.hasOwnProperty(status)) {
        return kStatusIcon[status];
    } else {
        return 'ft-trending-up';
    }
};

export const kActionToStatus = [
    kStatusAssigned,
    kStatusItemsRequired,
    kStatusAwaitsItems,
    kStatusItemsReceived
];