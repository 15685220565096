import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllTickets} from '../functional/api';
import ListPage from '../layout/page/ListPage';
import {kFilterTicketFields, kFilterTicketFieldsDefault} from '../functional/constants';
import AddTicketModal from '../components/modal/AddTicketModal';
import {withRouter} from 'react-router-dom';
import {toTicketPage} from '../functional/navigation';

import ListItem from '../components/ticket/TicketListItem';
import PersistentStateComponent from "../components/extends/PersistentStateComponent";

const ticketHeight = '310px';

class TicketsPage extends PersistentStateComponent {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        items: null,
        modalOpen: false,
    };
    
    state = TicketsPage.defaultState;

    constructor(props) {
        super(props);
        this.persistentExcludedState = ['items'];
        this.persistentIdentifier = 'pps-tickets-page';
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(TicketsPage.defaultState, this.update);
    }
    
    update = () => {
        getAllTickets((successful, data) => {
            if(successful) {
                this.setState({
                    items: data,
                });
            }
        });
    };
    
    render () {
        return (
            <ListPage
                persistentIdentifier={this.persistentIdentifier + '-list'}
                onRefresh={this.generalUpdate}
                paddingOverall
                deepSearch={true}
                loading={this.state.items == null}
                actions={[{
                    title: 'Adauga tichet',
                    icon: {title: 'ft-plus'},
                    onPress: () => {
                        this.setState({
                            modalOpen: true,
                        });
                    }
                }]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                defaultFields={kFilterTicketFieldsDefault}
                allFields={kFilterTicketFields}>

                <AddTicketModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }
    
    onItemClick = (item) => {
        toTicketPage(item.id);
    }
    
}

export default withRouter(TicketsPage);