import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import {getNecessaryItem, getSale} from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {filter, prettyDateAndTime} from '../functional/min-support';
import {toClientPage, toStockItemPage, toTicketPage} from '../functional/navigation';
import {openSale, translateSaleProducts} from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';

class NecessaryItemPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
    };
    
    state = NecessaryItemPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(NecessaryItemPage.defaultState, this.update);
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getNecessaryItem(key, (successful, data) => {
            if(successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let toolbarActions = [
            {
                title: 'Atribuie produs',
                onPress: () => {},
                icon: {title: 'ft-plus'}
            },
            {
                title: 'Adauga la comanda',
                onPress: () => {},
                icon: {title: 'ft-plus'}
            },
        ];
        
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    actions={toolbarActions}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                    <div className="h-100 overflow-scroll" style={{flex: 1, padding: 20}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
    
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'creat la data'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{marginBottom: 4}}/>
                                        
                                    <TextFieldDisplay
                                        label={'numar bucati:'}
                                        value={this.state.item.nb}
                                        icon={'ft-package'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        label={'solicitat pentru echipamentul'}
                                        value={this.state.item.equipment['title']}
                                        icon={'ft-clipboard'}
                                        style={{marginBottom: 4}}/>
    
                                    <TextFieldDisplay
                                        icon={'blank'}
                                        value={'Vezi tichetul'}
                                        action={'ft-eye'}
                                        onPress={() => {toTicketPage(this.state.item.equipment['ticket_id'])}}/>
                            
                                </div>

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NecessaryItemPage));