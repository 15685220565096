import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openUpdateModal, setItemPageTitle} from '../store/actions/application-store-actions';
import {getOperator} from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import {filter, prettyDateAndTime} from '../functional/min-support';
import {toTicketPage, toUserPage} from '../functional/navigation';
import {translateUserOperators, translateUserTickets} from '../functional/logic';
import {colorByStatus} from '../functional/status';
import {getLevelNameBasedOnLevel} from './UsersPage';

class UserPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };
    
    state = UserPage.defaultState;
    
    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
        if(this.props.match.params.key !== nextProps.match.params.key) {
            this.generalUpdate();
        }
    }
    
    componentDidMount() {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(UserPage.defaultState, this.update);
    }
    
    update = () => {
        let key = this.props.match.params.key;
        getOperator(key, (successful, data) => {
            if(successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data['first_name'] + ' ' + data['last_name']);
            }
        });
    };
    
    render () {
        let operators = this.state.item ? translateUserOperators(this.state.item.operators ? this.state.item.operators : []) : null;
        let tickets = this.state.item ? translateUserTickets(this.state.item.tickets) : null;
        let assigned = this.state.item ? translateUserTickets(this.state.item.assigned) : null;
        let toolbarActions = [
            {
                title: 'Adauga operator subordonat',
                onPress: () => {this.setState({modalOpen: true});},
                icon: {title: 'ft-plus'}
            },
        ];
        let toolbarLabels = [
            {
                connection: 'se cauta operatorii dupa:',
                tags: ['Numele operatorului', 'Prenumele operatorului', 'Numele de autentificare']
            },
            {
                connection: 'se cauta tichete dupa:',
                tags: ['Numarul tichetului', 'Statusul tichetului']
            }
        ];
        if(operators && this.state.search.length > 0) {
            operators = filter(operators, ['first_name', 'last_name', 'username'], this.state.search, false);
        }
    
        if(tickets && this.state.search.length > 0) {
            tickets = filter(tickets, ['name', 'status'], this.state.search, false);
        }
    
        if(assigned && this.state.search.length > 0) {
            assigned = filter(assigned, ['name', 'status'], this.state.search, false);
        }
        
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => {this.setState({search: newValue})}}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{flex: 1, padding: 20}}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
    
                                    <TextFieldDisplay
                                        label={'numele de autentificare'}
                                        value={this.state.item.username}
                                        style={{marginBottom: 4}}
                                        icon={'ft-lock'}/>
    
                                    <TextFieldDisplay
                                        label={'prenume utilizator'}
                                        value={this.state.item['first_name']}
                                        style={{marginBottom: 4}}
                                        icon={'ft-info'}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item['first_name'],
                                                'operator',
                                                'first_name');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'nume utilizator'}
                                        value={this.state.item['last_name']}
                                        style={{marginBottom: 4}}
                                        icon={'ft-info'}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item['last_name'],
                                                'operator',
                                                'last_name');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'nr. telefon utilizator'}
                                        value={this.state.item.phone}
                                        style={{marginBottom: 4}}
                                        icon={'ft-phone'}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.phone,
                                                'operator',
                                                'phone');
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'email utilizator'}
                                        value={this.state.item.email}
                                        style={{marginBottom: 4}}
                                        icon={'ft-mail'}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.email,
                                                'operator',
                                                'email');
                                        }}/>
    
                                    <TextFieldDisplay
                                        value={getLevelNameBasedOnLevel(this.state.item.level)}
                                        icon={'ft-layers'}
                                        style={{marginBottom: 4}}/>
                            
                                </div>
                                
                                {
                                    this.state.item.level == 2 &&
                                    <div className="general-card" style={{marginTop: 20}}>
                                        <TextFieldDisplay
                                            label={'nume companie'}
                                            value={this.state.item.company}
                                            style={{marginBottom: 4}}
                                            icon={'ft-info'}
                                            action={'ft-edit-2'}
                                            onPress={() => {
                                                this.props.openUpdateModal(
                                                    this.state.item.id,
                                                    this.state.item.company,
                                                    'operator',
                                                    'company');
                                            }}/>
    
                                        <TextFieldDisplay
                                            label={'cod identificare fiscala'}
                                            value={this.state.item.cif}
                                            style={{marginBottom: 4}}
                                            icon={'ft-hash'}
                                            action={'ft-edit-2'}
                                            onPress={() => {
                                                this.props.openUpdateModal(
                                                    this.state.item.id,
                                                    this.state.item.cif,
                                                    'operator',
                                                    'cif');
                                            }}/>
    
                                        <TextFieldDisplay
                                            label={'nr. registrul comertului'}
                                            value={this.state.item.reg}
                                            style={{marginBottom: 4}}
                                            icon={'blank'}
                                            action={'ft-edit-2'}
                                            onPress={() => {
                                                this.props.openUpdateModal(
                                                    this.state.item.id,
                                                    this.state.item.reg,
                                                    'operator',
                                                    'reg');
                                            }}/>
    
                                        <TextFieldDisplay
                                            label={'adresa sediu social'}
                                            value={this.state.item.address}
                                            style={{marginBottom: 4}}
                                            icon={'ft-map-pin'}
                                            action={'ft-edit-2'}
                                            onPress={() => {
                                                this.props.openUpdateModal(
                                                    this.state.item.id,
                                                    this.state.item.address,
                                                    'operator',
                                                    'address');
                                            }}/>
                                    </div>
                                }
                                
                                {
                                    operators && operators.length > 0 &&
                                    <h3 style={{marginTop: 20}}>
                                        Operatori subordonati
                                    </h3>
                                }
    
                                {
                                    operators && operators.length > 0 &&
                                    <div className="w-100 d-flex flex-row flex-wrap" style={{paddingLeft: 20, paddingBottom: 20}}>
                                        {
                                            operators.map((item) => {
                                                return (
                                                    <div style={{width: '33.3333%', paddingRight: 20, paddingBottom: 20}}>
                                                        <div className={'general-card'} style={{width: '100%'}}>
                                                            <TextFieldDisplay
                                                                value={item['first_name'] + ' ' + item['last_name']}
                                                                style={{marginBottom: 4}}/>
                    
                                                            <TextFieldDisplay
                                                                value={item.username}
                                                                icon={'ft-lock'}
                                                                style={{marginBottom: 4}}/>
    
                                                            <TextFieldDisplay
                                                                value={item.email}
                                                                icon={'ft-at-sign'}
                                                                style={{marginBottom: 4}}/>
    
                                                            <TextFieldDisplay
                                                                value={item.phone}
                                                                icon={'ft-phone'}
                                                                style={{marginBottom: 4}}/>
                    
                                                            <TextFieldDisplay
                                                                value={getLevelNameBasedOnLevel(item.level)}
                                                                icon={'ft-layers'}
                                                                style={{marginBottom: 4}}/>
    
                                                            <TextFieldDisplay
                                                                icon={'blank'}
                                                                action={'ft-eye'}
                                                                onPress={()=>{toUserPage(item['id'])}}
                                                                value={'Vezi utilizator'}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
    
                                {
                                    operators && operators.length <= 0 &&
                                    <h3 style={{marginTop: 20}}>
                                        Niciun operator subordonat
                                    </h3>
                                }

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                
                    <div className="h-100 overflow-scroll" style={{width: '40%', padding: 20, paddingLeft: 0}}>
                    
                        {
                            this.state.item && tickets && assigned &&
                            <React.Fragment>
    
                                {
                                    assigned && assigned.length > 0 &&
                                    <h3>
                                        Tichete atribuite momentan
                                    </h3>
                                }
    
                                {
                                    assigned.length > 0 && assigned.map((item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{marginBottom: 20}}>
                    
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{flex:1, paddingRight: 20}}>
                                                        <TextFieldDisplay
                                                            style={{marginBottom: 4}}
                                                            value={item.name}
                                                            icon={'ft-check-square'}/>
                            
                                                        <TextFieldDisplay
                                                            textBackgroundColor={colorByStatus(item.status)}
                                                            textColor={'#ffffff'}
                                                            value={item.status}
                                                            icon={'ft-code'}
                                                            style={{marginBottom: 4}}/>
                            
                                                        <TextFieldDisplay
                                                            value={prettyDateAndTime(item.created)}
                                                            icon={'ft-clock'}
                                                            style={{marginBottom: 4}}/>
                            
                                                        <TextFieldDisplay
                                                            value={item.type}
                                                            icon={'ft-clipboard'}
                                                            style={{marginBottom: 4}}/>
                            
                                                        <TextFieldDisplay
                                                            value={item.description}
                                                            icon={'ft-file-text'}
                                                            style={{marginBottom: 4}}/>
                            
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            action={'ft-eye'}
                                                            onPress={()=>{toTicketPage(item['id'])}}
                                                            value={'Vezi ticket'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
    
                                {
                                    assigned && assigned.length <= 0 &&
                                    <h3>
                                        Niciun tichet atribuit momentan
                                    </h3>
                                }
    
    
    
                                {
                                    tickets && tickets.length > 0 &&
                                    <h3>
                                        Tichete create
                                    </h3>
                                }
                                
                                {
                                    tickets.length > 0 && tickets.map((item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{marginBottom: 20}}>
    
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{flex:1, paddingRight: 20}}>
                                                        <TextFieldDisplay
                                                            style={{marginBottom: 4}}
                                                            value={item.name}
                                                            icon={'ft-check-square'}/>
    
                                                        <TextFieldDisplay
                                                            textBackgroundColor={colorByStatus(item.status)}
                                                            textColor={'#ffffff'}
                                                            value={item.status}
                                                            icon={'ft-code'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={prettyDateAndTime(item.created)}
                                                            icon={'ft-clock'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={item.type}
                                                            icon={'ft-clipboard'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            value={item.description}
                                                            icon={'ft-file-text'}
                                                            style={{marginBottom: 4}}/>
    
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            action={'ft-eye'}
                                                            onPress={()=>{toTicketPage(item['id'])}}
                                                            value={'Vezi ticket'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                
                                {
                                    tickets && tickets.length <= 0 &&
                                    <h3>
                                        Niciun tichet creat
                                    </h3>
                                }
                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => dispatch(setItemPageTitle(value)),
        openUpdateModal: (id, previous, type, on) => dispatch((openUpdateModal(id, previous, type, on))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserPage));